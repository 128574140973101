import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const SaleProductList = () => {

    const dispatch = useDispatch();
    const searchRef = useRef("");
    const [productData,setProductData] = useState([])

    const [productCounts,setProductCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('');
    const [recipes,setRecipe] = useState([])
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    let tablColumns = {
        id:'ID',
        code:'Code',
        weight:'Weight',
        size:'Size',
        description:'Description',
        supplier_description:'Supplier Description',
        recipes:'Recipes',
        status:'Status'
    }
    const getProductData = (pageNo, query='',recipe='') =>{
        let url = '/products/list?page='+pageNo+'&query='+query
        if(recipe){
            url +='&recipe='+recipe.value
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data.products)
                setProductCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getProductData(pageno,search,selectedRecipe)
    }
    const filterResult = () =>{
        getProductData(1,search,selectedRecipe)
    }
    const changeRecipe = (data)=>{
        setSelectedRecipe(data)
    }

    useEffect(()=>{
        getData('/purchaseproducts/filterrecipe').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setRecipe(responseData.data)
            }
        })
        getProductData(pageNo)
        dispatch(setPageTitle('Products'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Products',
                "link":'/products',
            }
    ]))
     // eslint-disable-next-line
    },[dispatch])
    const onSearchChange = (data) => {
        setSearchData(data)
     };
     const clearFilter = () => {
        setSearchData("")
        getProductData(1,"")
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':productData, 'column': tablColumns, counts:productCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/products/' }} updateFn = {paginationUpdate}
                        submitFilter={filterResult}  clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by Code, Description",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Select Recipe",
                                type:"select",
                                class:'form-control',
                                options:recipes,
                                default:selectedRecipe,
                                isClearable:true,
                                changeOptionsFn:changeRecipe
                            }
                        ]}
                        
                        
                        submenu={[
                                        {
                                            label:"Add New Product",
                                            link:'/products/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default SaleProductList;
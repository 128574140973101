import React from "react"
import { useLocation, Link,Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const location = useLocation();
    const currentURI = location.pathname;
    const currentData = useSelector((state) => state.currentData);
    if (!currentData.profile) {
        return <Navigate to="/" />
    }
    const permissions = currentData.profile.permissions;
    return(
        <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
                <Link className={`nav-link ${currentURI === '/dashboard' ? " active" : ""}`} to={"/dashboard"}>
                  <>
                  <i className="bi bi-grid"></i>
                    <span>Dashboard</span>
                  </>
                </Link>
            </li>
           
            { permissions && permissions.length > 0 && permissions.map((module, index) => (
              
                module.sub.length > 0 ?  
                        
                    <li className={`nav-item`} key={index}> 
                    <a className={`nav-link ${module.slacks.includes(currentURI) ? "active" : " collapsed"}`} data-bs-target={"#sales-nav-"+module.module_id} data-bs-toggle="collapse" href="/#" aria-expanded={ module.slacks.includes(currentURI) ? "true" : "false"} >
                        <i className={`bi bi-${module.icon}`}></i><span>{module.module_name}</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    
                    <ul id={"sales-nav-"+module.module_id} className= {`nav-content collapse ${module.slacks.includes(currentURI) ? " show" : ""}`}  data-bs-parent={"sales-nav-"+module.module_id} >
                    { module.sub.map((sub, index) => (
                    <li className="nav-item" key={index}>
                            <Link className={`nav-link ${sub.slack.includes(currentURI) ? " active" : ""}`} to={sub.slack}>
                            <>
                            <i className={`bi bi-${sub.icon}`} ></i>
                                <span>{sub.module_name}</span>
                                </>
                            </Link>
                        </li>
                    )) }
                    </ul>
                </li>
                : <li className="nav-item" key={index}>
                    <Link className={`nav-link ${currentURI.includes(module.slack) ? " active" : ""}`} to={module.slack}>
                    <>
                    <i className={`bi bi-${module.icon}`} ></i>
                        <span>{module.module_name}</span>
                        </>
                    </Link>
                </li>
             ))
            }
            {currentData.profile.roleid ===1 &&
            <li className="nav-item">
                <Link className={`nav-link ${currentURI === '/webpermissions' ? " active" : ""}`} to={"/webpermissions"}>
                   <>
                   <i className="bi bi-browser-edge"></i>
                    <span>Web Permissions</span>
                   </>
                </Link>
            </li>
            }
        </ul>
    </aside>
)
}
export default Sidebar;

import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { useSearchParams } from 'react-router-dom';
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';


const SaleOrderList = () => {
    const searchRef = useRef("");
    const [searchParams,setSearchParmas] = useSearchParams();
    const dispatch = useDispatch();
    const [orderData,setOrderData] = useState([])

    const [orderCounts,setOrderCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [defaultStatus, setDefaultStatus] = useState(null);
    const [statusData, setStatusData] = useState([]);
    const [clientData,setClientData] = useState([])
    const [selectedClient,setSelectedClient] = useState(0)

    let tablColumns = {
        id:'ID',
        sage_code:'Order Number',
        client_name:'Client Name',
        total_quanitity:'Total Quantity',
        order_date:'Order Date',
        delivery_date:'Delivery Date',
        location:'Location',
        order_status:'Status',
        production_sync:'In Production',
        

    }
    const getOrderData = (pageNo, query='',date='',status='', client='') =>{

        let url = '/orders/list?page='+pageNo+'&query='+query+'&client='+client;
        if(date){
            url +='&start='+date.start+'&end='+date.end
        }
        if(status){
            url +='&status='+status
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setOrderData(responseData.data.orders)
                setOrderCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let client = ''
         if(selectedClient)
            client = selectedClient.value
        getOrderData(pageno,search,date,selectedStatus,client)
    }
    const filterResult = () =>{
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         let client = ''
         if(selectedClient)
            client = selectedClient.value
         getOrderData(1,search,date,selectedStatus,client)
    }
    const clearFilter = () => {
       
        setSelectedStatus(null)
        setDefaultStatus(null)
        setSearchParmas({'status':''})
        setSearchData("")
        getOrderData(1,"")
        setSelectedClient(null)
        searchRef.current.value = ''
    }
    const onSearchChange = (data) => {
        setSearchData(data)
     };
     const changeStatus = (data)=>{
        if(data) {
            setSearchParmas({'status':data.value})
            setSelectedStatus(data.value)
            setDefaultStatus(data)
         } else{
            setSelectedStatus(null)
            setDefaultStatus(null)
            setSearchParmas({'status':''})
         }
     }
     const onDateRangeChange = (dates) => {
         const [start, end] = dates;
         setStartDate(start);
         setEndDate(end);
     };
     const ChangeClientHandler = (res) => {
        if(res)
         setSelectedClient(res)
        else
            setSelectedClient(null)
     };


    useEffect(()=>{
        getData('/orders/status').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setStatusData(responseData.data)
            }
        })
            getData('/clients/filter').then((response)=>{
                if(response.status === 200){
                    let responseData = response.data
                    setClientData(responseData.data)
                }
            })
        
        dispatch(setPageTitle('Sale Orders'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Sale Orders',
                "link":'/orders',
            }
    ]))
    if(searchParams && searchParams.get('status')){
        var queryStringsStatus  =searchParams.get('status')
        if(queryStringsStatus === "1"){
            setSelectedStatus(1)
            setDefaultStatus({label: "Pending", value:1})
        }
        if(queryStringsStatus === "2"){
            setSelectedStatus(2)
            setDefaultStatus({"label":"QA Pending","value":2})
        }
        if(queryStringsStatus === "3"){
            setSelectedStatus(3)
            setDefaultStatus({"label":"QA Approved","value":3})
        }
        if(queryStringsStatus === "4"){
            setSelectedStatus(4)
            setDefaultStatus({"label":"Complete","value":4})
        }
    }
  setTimeout(()=>{
    let date = {
        'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
        'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
     }
    let client = ''
    if(selectedClient)
       client = selectedClient.value
    getOrderData(pageNo, search, date, queryStringsStatus,client)
  },500)

    // eslint-disable-next-line
    },[dispatch])

    // const downloadPdf = (id) => {
    //     getData('/print/order/'+id).then((response)=>{
    //         if(response.status === 200){
    //             let responseData = response.data
    //             if(responseData.success)
    //             {
    //                 DownloadZip(responseData.data.folder);
    //                 return;
    //                // fileDownload(responseData.data.filename)
    //                 // window.open(process.env.REACT_APP_IMAGE_URL+responseData.data.filename+'&type=pdf', '_blank', 'noreferrer');
    //                 // return
    //             }
    //             AlertError(responseData.message)

    //         }
    //     })
      
    // }
    // const DownloadZip = (fileURL) => {
    //     window.open(
    //         process.env.REACT_APP_IMAGE_URL + fileURL + "&type=zip",
    //         "_blank",
    //         "noreferrer"
    //     );
    //     return;
    // };
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':orderData, 'column': tablColumns, counts:orderCounts, pageno: pageNo,limit:limit, isNext:isNext, 'viewLink': '/orders/details/'}} updateFn = {paginationUpdate}  //downloadPdf={downloadPdf}  
                        
                        submitFilter={filterResult}
                        clearfilter={clearFilter}
                    
                        filters={[         
                            {
                                label:"Find by Order Number",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Date Range",
                                type:'daterange',
                                class:'form-control',
                                startDate:startDate,
                                endDate:endDate,
                                placeholderText:'Select Date Range',
                                dateRangeFn:onDateRangeChange
                            },
                            {
                                label:"Select Client",
                                type:"select",
                                class:'form-control',
                                options:clientData,
                                default:selectedClient,
                                changeOptionsFn:ChangeClientHandler
                            },
                            {
                                label:"Select status",
                                type:"select",
                                class:'form-control',
                                options:statusData,
                                default:defaultStatus,
                                changeOptionsFn:changeStatus
                            }
                        ]}
                        
                        submenu={[
                                        {
                                            label:"New",
                                            link:'/orders/add',
                                            buttonClass:'primary float-end '

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default SaleOrderList;
import React, { useEffect, useState,useRef  } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import Table from "../../components/Table";
import { getData } from "../../services/HttpServices";
import { setPageTitle  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import AddQuanitityPopUp from "./addquantitypopup";
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
const Inventory = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();
    const [productData,setProductData] = useState([])
    const [productCounts,setProductCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [showAddQty,setShowAddQty] = useState(false)
    const [limit,setLimit] = useState(10)
  
    const [isLoading, setIsLoading] = useState(true);
    const [search,setSearchData] = useState("")
    const [selectedId,setSelectedId] = useState(0)
    const [categories,setCategoryData] = useState(10)
    const [selectedCategory, setSelectedCategory] = useState('');

    let tablColumns = {
        product_code:'Code',
        description:'Description',
        total_quantity:'Quantity'
    }

    const getProductData = (pageNo, query='',category='') =>{
        setIsLoading(true)
        if(category){
            category = category.value
        }
        getData('/inventory/list?page='+pageNo+'&query='+query+'&category='+category).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data.products)
                setProductCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
            setIsLoading(false)
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        setSearchData(search)
        setSelectedCategory(selectedCategory)
        getProductData(pageno,search,selectedCategory)
    }
    const filterResult = () =>{
        getProductData(1,search,selectedCategory)
    }
    
    const updateSearch = (data) => {
        setSearchData(data)
    }
    useEffect(()=>{
        getProductData(pageNo)
        dispatch(setPageTitle('Inventory'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Inventory',
                "link":'/inventory',
            }
    ]))
    getData('/puchasecategory/filter').then((response)=>{
        if(response.status === 200){
            let responseData = response.data
            setCategoryData(responseData.data)
        }
    })
    // eslint-disable-next-line
    },[dispatch])

    const showSaleGrid = (id) => {
        setSelectedId(id)
        setShowAddQty(true);
    };
    const hideAddQty = () => {
        setShowAddQty(false);
        getProductData(pageNo)
    };
    const changeCategory = (data)=>{
        setSelectedCategory(data)
    }
    const clearFilter = () => {
        searchRef.current.value = ''
        getProductData(1)
        setSelectedCategory("")
        setSearchData("")
    }
    return(
        <DefaultLayout>
            <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':productData, 'column': tablColumns, counts:productCounts, pageno: pageNo,limit:limit, isNext:isNext ,viewLink:'/singleinventoryhistory/' }} updateFn = {paginationUpdate} submitFilter={filterResult} clearfilter={clearFilter} popupButton={showSaleGrid} 
                        filters={[         
                            {
                                label:"Find by Code, Description",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            },{
                                label:"Select Category",
                                type:"select",
                                class:'form-control',
                                options:categories,
                                default:selectedCategory,
                                changeOptionsFn:changeCategory
                            }
                        ]}
                        isLoading={isLoading}/>    
                        </div>
                        </div> 
                        </div>
                        </section>
                      <AddQuanitityPopUp
                       show={showAddQty}
                       id={selectedId}
                       hideFn={hideAddQty}
                      />
        </DefaultLayout>
    )

}
export default Inventory
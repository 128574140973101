import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const EditAddress = () => {
    const dispatch = useDispatch();
    /*Fetch user id */
    const navigate = useNavigate()
    const { id } = useParams()
    const [clientId, setClientId] = useState();

    /*Inital state data*/
    const [ClientData, setClientData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/clients/address/update/'+clientId +'/'+id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/clients/addresslist");
        }).catch(e => {
            AlertError(e);
        })
    }
    useEffect(() => {
        /*Get Client data*/

        const getClientData = () => {
            getData('/clients/addressdetails/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setClientData(responseData.data)
                        setClientId(responseData.data.client_id)
                    } else {
                        AlertError(responseData.message)
                        navigate("/clients")
                    }
                    setIsLoading(true)
                }
            })
        }
        setTimeout(() => {
            getClientData()
        }, 1000)

        dispatch(setPageTitle('Edit Client Address'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Clients Address',
                "link": '/clients/addresslist',
            },
            {
                "name": 'Edit Client Address',
                "link": '/clients/address/' + id,
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                 <div className="card-body">
                            <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                         <div className="row">
                               <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="client" className="col-sm-12 col-form-label">Client<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" disabled className="form-control" id="client" defaultValue={ClientData.client_name} />
                                    </div>
                                   
                                </div>
                               <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="code" className="col-sm-12 col-form-label">GLN Code<span className="text-danger">*</span>
                                    <small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="Code Ref: DONCASTER: 5000119000954,DIDCOT: 5000119001157,RAINHAM: 5000119001351,SNODLAND: 5000119001759,HINCKLEY: 5000119001953,SOUTHAMPTON: 5000119002350,PETERBOROUGH: 5000119003157,LIVINGSTON: 5000119005052,BELFAST: 5000119008350,AVONMOUNTH: 5000119008459,WIDNES: 5000119008756,	DUBLIN:5000119007353, "></i></small>
                                    
                                    </label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={ClientData.gln_code}  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="code" className="col-sm-12 col-form-label">Location<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="location" defaultValue={ClientData.location}  {...register("location", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.location.type === "required" && <span className="error">Please enter location</span>}
                                        {errors?.code && errors.location.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactname" className="col-sm-12 col-form-label">Contact Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactname" defaultValue={ClientData.contact_name} {...register("contactname", {required: true , maxLength: 20})} />
                                        {errors?.contactname && errors.contactname.type === "required" && <span className="error">Please enter contact name</span>}
                                        {errors?.contactname && errors.contactname.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactnumber" className="col-sm-12 col-form-label">Contact Number<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactnumber" defaultValue={ClientData.contact_number} {...register("contactnumber", {required: true , maxLength: 20})} />
                                        {errors?.contactnumber && errors.contactnumber.type === "required" && <span className="error">Please enter contact number</span>}
                                        {errors?.contactnumber && errors.contactnumber.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address1" className="col-sm-12 col-form-label">Address 1<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address1" defaultValue={ClientData.address_1} {...register("address1", {required: true , maxLength: 128})} />
                                        {errors?.address1 && errors.address1.type === "required" && <span className="error">Please enter address 1</span>}
                                        {errors?.address1 && errors.address1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_2" className="col-sm-12 col-form-label">Address 2</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address_2" defaultValue={ClientData.address_2} {...register("address_2", {required: false , maxLength: 128})} />
                                        {errors?.address_2 && errors.address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="county" className="col-sm-12 col-form-label">County<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="county" defaultValue={ClientData.county} {...register("county", {required: true , maxLength: 20})} />
                                        {errors?.county && errors.county.type === "required" && <span className="error">Please enter county</span>}
                                        {errors?.county && errors.county.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="country" className="col-sm-12 col-form-label">Country<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="country" defaultValue={ClientData.country} {...register("country", {required: true , maxLength: 20})} />
                                        {errors?.country && errors.country.type === "required" && <span className="error">Please enter country</span>}
                                        {errors?.country && errors.country.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Post Code</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="postcode" defaultValue={ClientData.postcode} {...register("postcode", {required: false , maxLength: 10})} />
                                        {errors?.postcode && errors.postcode.type === "required" && <span className="error">Please enter post code</span>}
                                        {errors?.postcode && errors.postcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={ClientData.status} {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                   </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                          </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditAddress;
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, useParams } from "react-router-dom";
import { getData } from "../../services/HttpServices";
import { setPageTitle } from "../../store/resourceSlice";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../store/breadcrumbSlice";
import Pagination from "../../components/Pagination";
import ReactECharts from "echarts-for-react";
import Fruitmasterpopup from "../inventory/fruitmasterpopup";
const ProductInventoryHistory = () => {
  const dispatch = useDispatch();
  /*Fetch user id */
  const { id } = useParams();

  const [historyData, setHistoryData] = useState([]);
  const [historyCounts, setHistoryCounts] = useState(0);
  const [pageNo, setPageNo] = useState(1);
 // const [isNext, setIsNext] = useState(false);
  const [limit, setLimit] = useState(10);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [supplierOrders, setSupplierOrders] = useState([]);
  const [saleOrders, setSaleOrders] = useState({});
  const [poOrders, setPoOrders] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [topClientsOrders, setTopClientsOrders] = useState({});
  const [weeklyDeliveryOrders, setWeeklyDeliveryOrders] = useState({});
  const [topClientsSaleOrders, setTopClientsSaleOrders] = useState({});
  const [weeklyDeliverySaleOrders, setWeeklyDeliverySaleOrders] = useState({});
  const [topSupplierOrders, setTopSupplierOrders] = useState({});
  const [fruit, setFruit] = useState(false);

  const getHistoryData = (pageNo) => {
    getData("/inventory/history?page=" + pageNo + "&productid=" + id).then(
      (response) => {
        if (response.status === 200) {
          let responseData = response.data;
          setHistoryData(responseData.data.history);
          setHistoryCounts(responseData.data.counts);
          setPageNo(responseData.data.page_no);
          //setIsNext(responseData.data.is_next);
          setLimit(responseData.data.limit);
          setProduct(responseData.data.product);
          setSupplierOrders(responseData.data.supplier_orders);
          //setSupplierAvgPrice(responseData.data.supplier_avg_price);
          setSaleOrders(responseData.data.so_orders);
          setTopClientsSaleOrders(responseData.data.so_top_suppliers);
          setPoOrders(responseData.data.po_orders);
          setTopSupplierOrders(responseData.data.po_top_suppliers);
        }
      }
    );
  };
  const paginationUpdate = (pageno) => {
    setPageNo(pageno);
    getHistoryData(pageno);
  };
  useEffect(() => {
    getHistoryData(pageNo);
    dispatch(
      setBreadcrumbs([
        {
          name: "Dashboard",
          link: "/dashboard",
        },
        {
          name: "Inventory",
          link: "/inventory",
        },
        {
          name: "History",
          link: "/singleinventoryhistory/" + id,
        },
      ])
    );
    dispatch(setPageTitle("Inventory History"));


   
    //eslint-disable-next-line
  }, [pageNo, dispatch]);
  const updateList = (number) => {
    setCurrentPage(number);
    paginationUpdate(number);
  };
  useEffect(()=>{
    if(saleOrders.dates !== undefined){
      setWeeklyDeliverySaleOrders({
        title: {
          text: 'Weekly Deliveries',
          x:'top'
        },
        tooltip : {},
        legend: {
          data:['Orders','Qty'],
          top: 'bottom'
        },
        xAxis: {
          data: saleOrders.dates
        },
        yAxis: {},
        series: [{
          name: 'Orders',
          type: 'bar',
          data:saleOrders.ordercounts
        },{
          name: 'Qty',
          type: 'line',
          data: saleOrders.quantity
        }]
      })
    }
    if(topClientsSaleOrders){
      setTopClientsSaleOrders({
        title : {
          text: 'Top Clients',
          subtext: 'Weekly Status',
          x:'center'
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d})"
        },
        legend: {
          show:false
        },
        series : [
          {
          name: 'Weekly Sale',
          type: 'pie',
          radius : '55%',
          center: ['50%', '60%'],
          data:topClientsSaleOrders.seriesdata,
          itemStyle: {
            emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
          }
        ]
      })
    }
     // eslint-disable-next-line
  },[saleOrders])

  useEffect(()=>{
    if(poOrders.dates !== undefined){
      setWeeklyDeliveryOrders({
        title: {
          text: 'Weekly Deliveries',
          x:'top'
        },
        tooltip : {},
        legend: {
          data:['Orders','Avg Price','Qty'],
          top: 'bottom'
        },
        xAxis: {
          data: poOrders.dates
        },
        yAxis: {},
        series: [{
          name: 'Orders',
          type: 'bar',
          data: poOrders.ordercounts
        },{
          name: 'Avg Price',
          type: 'line',
          data: poOrders.avg
        },{
          name: 'Qty',
          type: 'line',
          data: poOrders.quantity
        }]
      })
    }
    if(topSupplierOrders){
      setTopClientsOrders({
        title : {
          text: 'Top Suppliers',
          subtext: 'Weekly Status',
          x:'center'
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d})"
        },
        legend: {
          show:false
        },
        series : [
          {
          name: 'Weekly Sale',
          type: 'pie',
          radius : '55%',
          center: ['50%', '60%'],
          data:topSupplierOrders.seriesdata,
          itemStyle: {
            emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
          }
        ]
      })
    }
   
    
     // eslint-disable-next-line
  },[poOrders])

  const showFruitMaster = (location) => {
    if(!location)
       return;
      
    setSelectedLocation(location)
    setFruit(true);
  };
  const hideMasterActiveStock = () => {
      setFruit(false);
  };
  return (
    <DefaultLayout>
      <section className="section">
        <div className="card">
          <div className="card-body mt-2">
            {product && (
              <div className="row">
                <div className="d-flex row bg-default">
                  <div className="col-6">
                    <div className="card-body text-nowrap">
                      <h5 className="card-title mb-0">
                        Product Information! 🎉
                      </h5>
                      <p className="mb-2">
                        <span className="fw-bold">Code: </span>
                        {product.product_code}
                      </p>
                      <p className="mb-2">
                        <span className="fw-bold">Description: </span>
                        <Link to={"/purchaseproducts/" + product.id}>
                          {product.description}
                        </Link>
                      </p>
                      <p className="mb-2">
                        <span className="fw-bold">Category: </span>
                          {product.categoroy_title}
                      </p>
                      <p className="mb-2">
                        <span className="fw-bold">Quantity: </span>
                        {product.total_quantity}
                      </p>

                      <h5 className="card-title mb-0">
                        Recent Pricing
                      </h5>
                      <table className="table table-primary table-striped">
                      <tbody>
                        <tr>
                        <th scope="col">Min Price</th>
                        <td> {product.itemprices ? product.itemprices.min_price : 0}</td>
                        </tr>
                        <tr>
                        <th scope="col">Max Price</th>
                        <td> {product.itemprices ? product.itemprices.max_price : 0}</td>
                        </tr>
                        <tr>
                        <th scope="col">Avg Price</th>
                        <td> {product.itemprices ? product.itemprices.avg_price : 0}</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body">
                      <h5 className="card-title mb-0">
                        Last 5 Supplier Order(s)
                      </h5>
                      <div className="table-responsive">
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Supplier</th>
                            <th>Quantity</th>
                            <th>Location</th>
                            <th>Price</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {supplierOrders.length >0 ? supplierOrders.map((order, index) => (<tr key={index}>
                            <td><Link to={'/purchaseorders/details/'+order.order_id} target="_blank">{order.order_id}</Link></td>
                            <td>{order.supplier_name}</td>
                            <td>{order.quantity}</td>
                            <td><Link  onClick={()=> showFruitMaster(order.locations) }>{order.location}</Link></td>
                            <td>{order.price}</td>
                            <td>{order.delivery_date}</td>
                          </tr>
                          )):<tr  className="alert alert-warning text-center">
                             <td colSpan={6}>No records found!</td>
                          </tr>
                        }
                        </tbody>

                      </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
     {saleOrders.dates !== undefined && saleOrders.dates.length > 0 && <div className="card">
          <div className="card-body mt-2">
            <div className="row">
              <div className="panel-heading mb-3">
                <h5 className="p-3">
                  <b>Sale Orders</b>
                </h5>
              </div>

              <div className="card-body mt-3">
             <div className="row">
             <div className="col-12 col-md-6">
              <ReactECharts
                option={weeklyDeliverySaleOrders}
                style={{ height: 400 }}
              />
              </div>
              <div className="col-12 col-md-6">
                {topClientsSaleOrders && <ReactECharts
                  option={topClientsSaleOrders}
                  style={{ height: 400 }}
                />
              }</div>
             </div>
                {/* <div className="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
                  <div className="datatable-container table-responsive">
                    <table className="table table-hover datatable datatable-table">
                      <thead>
                        <tr className="bg-simply-primary text-white fw-bold">
                          <th scope="col">Product</th>
                          <th scope="col">Description</th>
                          <th scope="col">Recipe</th>
                          <th scope="col">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {saleOrders.length > 0 ? (
                          saleOrders.map((data, index) => (
                            <tr data-index={index} key={index}>
                              <td>{data.code}</td>
                              <td>{data.description}</td>
                              <td>{data.title}</td>
                              <td>
                                {data.quantity}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <div className="alert alert-warning text-center">
                                No Records Found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div> }

        {poOrders.dates !== undefined && poOrders.dates.length > 0 && <div className="card">
          <div className="card-body mt-2">
            <div className="row">
              <div className="panel-heading mb-3">
                <h5 className="p-3">
                  <b>Goods Received</b>{" "}
                  {/* <span className="badge bg-primary">
                    Avg Price : {supplierAvgPrice}
                  </span> */}
                </h5>
              </div>

              <div className="card-body mt-3">
             <div className="row">
             <div className="col-12 col-md-6">
              {weeklyDeliveryOrders && <ReactECharts
                option={weeklyDeliveryOrders}
                style={{ height: 400 }}
              />}
              </div>
              <div className="col-12 col-md-6">
                {topClientsOrders && <ReactECharts
                  option={topClientsOrders}
                  style={{ height: 400 }}
                />
              }</div>
             </div>
                {/* <div className="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
                  <div className="datatable-container table-responsive">
                    <table className="table table-hover datatable datatable-table">
                      <thead>
                        <tr className="bg-simply-primary text-white fw-bold">
                          <th scope="col">Order Number</th>
                          <th scope="col">Supplier</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Delivery Date</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplierOrders.length > 0 ? (
                          supplierOrders.map((data, index) => (
                            <tr data-index={index} key={index}>
                              <td>
                                <Link
                                  to={
                                    "/purchaseorders/details/" + data.order_id
                                  }
                                  target="_blank"
                                >
                                  {data.order_id}
                                </Link>
                              </td>
                              <td>{data.supplier_name}</td>
                              <td>{data.quantity}</td>
                              <td>{data.delivery_date}</td>
                              <td>
                                {data.symbol}
                                {data.price}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <div className="alert alert-warning text-center">
                                No Records Found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>}
        <div className="card">
          <div className="card-body mt-2">
            <div className="row">
              <div className="panel-heading mb-3">
                <h5 className="p-3">
                  <b>History</b>
                </h5>
              </div>

              <div className="card-body mt-3">
                {historyData && historyData.length > 0 ? (
                  <ul className="notification">
                    {historyData.map((history, hIndex) => (
                      <li key={hIndex}>
                        <div className="notification-time">
                          <span className="date fw-bold"> {history.date}</span>
                          <span className="time ms-2 "> {history.time}</span>
                        </div>
                        <div className="notification-icon">
                          <span></span>
                        </div>
                        <div className="notification-time-date mb-2 d-block d-md-none">
                          <span className="date fw-bold"> {history.date}</span>
                          <span className="time ms-2 "> {history.time}</span>
                        </div>
                        <div className="notification-body">
                          <div className="media mt-0">
                            <div className="media-body ms-3 d-flex">
                              <div className="">
                                <p
                                  className="tx-14 text-dark mb-0 tx-semibold fw-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: history.heading,
                                  }}
                                ></p>
                                <p
                                  className="tx-14 text-dark mb-0 tx-semibold"
                                  dangerouslySetInnerHTML={{
                                    __html: history.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="alert alert-warning text-center">
                    {" "}
                    No History Found !
                  </div>
                )}
              </div>
              {historyCounts > 10 && (
                <Pagination
                  itemsCount={historyCounts}
                  itemsPerPage={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={true}
                  updateList={updateList}
                />
              )}
            </div>
          </div>
        </div>

        {/* <Table data={{'list':historyData, 'column': tablColumns, counts:historyCounts, pageno: pageNo,limit:limit, isNext:isNext }} updateFn = {paginationUpdate}
                        />     */}
      </section>
      <Fruitmasterpopup
        location={selectedLocation}
        show={fruit}
        hideFn={hideMasterActiveStock}
      />
    </DefaultLayout>
  );
};
export default ProductInventoryHistory;

import React, { useEffect } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, } from "react-router-dom"
import { postData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const AddLocation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/puchaselocation/add', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/locations");
        }).catch(e => {
            AlertError(e);
        })
    }
    useEffect(() => {
        dispatch(setPageTitle('Add Warehouse Location'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Warehouse Locations',
                "link": '/locations',
            },
            {
                "name": 'Add Warehouse Location',
                "link": '/locations/add',
            }
        ]))
    }, [dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                               <div className="card-body">
                            <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-6 col-xl-6">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                 <div className="row">
                               <div className="col-lg-12 col-xl-12 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Title<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="title" defaultValue=''  {...register("title", {required: true , maxLength: 20})}/>
                                        {errors?.title && errors.title.type === "required" && <span className="error">Please enter title</span>}
                                        {errors?.title && errors.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    <label htmlFor="label-room" className="col-sm-12 col-form-label">Label Room<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="label-room" defaultValue=''  {...register("labelroom", {required: true , maxLength: 20})}/>
                                        {errors?.title && errors.title.type === "required" && <span className="error">Please enter label room</span>}
                                        {errors?.title && errors.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div> 
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                   </div>
                            </form>
                        </div>
                        </div>
                        </div>
                                       </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default AddLocation;
import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { AddButton } from "../../components/Button"
import { setPageTitle  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const TypeList = () => {
    const dispatch = useDispatch();
    const [search,setSearch] = useState('')
    const [typeData,setTypeData] = useState([])
    const [typeCounts,setTypeCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
   let tablColumns = {
    id:'ID',
    code:'Code',
    title:'Title',
    status:'Status',
   }
    const getTypesData = (page, query='') =>{
        getData('/itemtypes/list?page='+page+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setTypeData(responseData.data.itemtypes)
                setTypeCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getTypesData(pageno,search)
    }
    const filterResult = (query) =>{
        setSearch(query)
        getTypesData(1,query)
    }
    useEffect(()=>{
        getTypesData(pageNo)
        dispatch(setPageTitle('Product Item Types'))
        // eslint-disable-next-line
    },[dispatch])

    return(
        <DefaultLayout pageTitle='Item Types'>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                    <div className="row">
                                <div className="col-12 btn-add-div">
                                   <AddButton buttonText="Add Item Type" link="/itemtypes/add"  buttonClass='primary float-end'/>
                                </div>
                            </div>
                        <div className="table-responsive">
                            
                        <Table data={{list:typeData, counts:typeCounts, pageno: pageNo,limit:limit, isNext:isNext,column: tablColumns , editlink: '/itemtypes/' }} updateFn = {paginationUpdate} searchFn={filterResult} />
                        </div>
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default TypeList;
import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
export const PrintActiveStock = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section invoice-section">
        <div className="row">
          <div className="card-body mt-3">
            <div className="card">
              <div className="card-body" ref={ref}>
              <table
              className="table"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              style={{ border: '1px solid #dddddd',borderRight:'1px solid #dddddd' }}
            >
              <thead>
                <tr className="table-title">
                  <th>No.</th>
                  <th>Code</th>
                  <th>Cat.</th>
                  <th>Description</th>
                  <th>Loc 1 </th>
                  <th>Imp. QTY.</th>
                  <th>Total QTY</th>
                  <th>Weekly Runrate</th>
                  <th>Run out Days</th>
                  <th>Run out Date</th>
                  <th>Date</th>
                  <th>Next delivery date</th>
                  <th>Days overlap</th>
                  <th>Next delivery QTY</th>
                  <th>Run out days after delivery</th>
                  <th>Next run out date</th>
                </tr>
              </thead>
              <tbody>
              {  props.inventoryData.map((product,index) => (
                <tr key={index}>
                  <td>{product.id}</td>
                  <td>{product.product_code}</td>
                  <td>{product.categoroy_title}</td>
                  <td>{product.description}</td>
                  <td>{product.inventory ? product.inventory.quantity : 0}</td>
                  <td>{product.inventory ? product.inventory.imp_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.total_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.weeklyrunrate : 0}</td>
                  <td>{product.inventory ? product.inventory.runsdayout : 0}</td>
                  <td>{product.inventory ? product.inventory.runoutdate : ''}</td>
                  <td>{product.inventory ? product.inventory.order_date : ''}</td>
                  <td>{product.inventory ? product.inventory.next_delivery_date : ''}</td>
                  <td>{product.inventory ? product.inventory.days_over_lap : 0}</td>
                  <td>{product.inventory ? product.inventory.next_delivery_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.run_out_days_after_delivery : 0}</td>
                  <td>{product.inventory ? product.inventory.runoutdate : ''}</td>
                </tr>
                ))
               }
              </tbody>
            </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});

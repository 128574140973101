import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm } from "react-hook-form";
import { setPageTitle } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const AddRecipe = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [subRecipeData, setSubRecipeData] = useState([]);
  const [combinationRows, setCombinationRows] = useState([
    { recipe_sub_id: 0, recipe_value: 0  },
  ]);
  /*Add Product Data*/
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (combinationRows.length === 0) {
      AlertError("Please add a recipe combination.");
      return;
    }
    let errors = combinationRows.filter((res) => {
      return (
        res.recipe_sub_id.value === 0 || res.recipe_value === 0
      );
    });
    if (errors.length > 0) {
      AlertError(
        "The Combination Information section required attention. All fields are required."
      );
      return;
    }
    var totalPercentage = 0
    combinationRows.map((data)=>(
        totalPercentage = totalPercentage + + data.recipe_value
    ))
    if(totalPercentage > 100 || totalPercentage < 100){
        AlertError("The total of percentages should be 100");
        return
    }
     data.combinations = combinationRows;
    postData("/recipes/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/recipes");
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  const addCombination = () => {
    let newAdded = [
      ...combinationRows,
      { recipe_sub_id: 0, recipe_value: 0 },
    ];
    setCombinationRows(newAdded);
  };
  const removeCombination = (index) => {
    let newRows = combinationRows.filter((arrow, key) => key !== index);
    setCombinationRows(newRows);
  };

  const updateCombinationRow = (res, index) => {
    let existOptionLength = 0
    if(res){
        const existOption =  combinationRows.filter((data)=>(
            res.value === data.recipe_sub_id.value
        ))
        existOptionLength = existOption.length
    }
  
    if(existOptionLength){
        AlertError("This recipe is already selected");
        combinationRows[index]["recipe_sub_id"] = {label: '', value : 0}
        return
    }
    let combmnation = res ? res : {};
    combinationRows[index]["recipe_sub_id"] = combmnation;
  };
  const updateCombinationPercentage = (res, index)=>{
    let temp = [...combinationRows]
    temp[index]["recipe_value"] = res.target.value;
    setCombinationRows(temp)
  };

  useEffect(() => {
    const filtersubrecipes = () => {
      getData("/subrecipes").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setSubRecipeData(responseData.data);
          }
        }
      });
    };
    filtersubrecipes();
    dispatch(setPageTitle("Add New Recipe & Combinations"));
    dispatch(setBreadcrumbs([
      {
      "name":'Dashboard',
      "link":'/dashboard',
      },
      {
          "name":'Recipes',
          "link":'/recipes',
      }
      ,
      {
          "name":'Add New Recipe & Combinations',
          "link":'/recipes/add',
      }
]))
  }, [dispatch]);
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <label htmlFor="name" className="col-sm-2 col-form-label">
                    Title<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      defaultValue=""
                      {...register("title", { required: true, maxLength: 50 })}
                    />
                    {errors?.title && errors.title.type === "required" && (
                      <span className="error">Please enter title</span>
                    )}
                    {errors?.title && errors.title.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                  </div>
                </div>
                <hr />
                <div className="col-12 mb-3">
                  <h5 className="card-title">Recipe Combinations Information<span className="text-danger">*</span></h5>
                </div>
                {combinationRows.length > 0 &&
                  combinationRows.map((combmnation, index) => (
                    <div className="row g-3 mb-3" key={index}>
                      <div className="col-3">
                        <label className="col-form-label">Sub Recipe<span className="text-danger">*</span></label>
                        <Select
                          options={subRecipeData}
                          defaultValue={combmnation.recipe_sub_id}
                          className="col-12"
                          isSearchable={true}
                          isClearable={true}
                          onChange={(res) => updateCombinationRow(res, index)}
                        />
                      </div>
                      <div className="col-3">
                        <label className="col-form-label">Percentage<span className="text-danger">*</span></label>
                        <input
                          type="number"
                          className="form-control"
                          min={1}
                          defaultValue={1}
                          onInput={(res) => updateCombinationPercentage(res,index)}
                        />
                      </div>
                      {combinationRows.length > 1 && (
                        <div className="col-3">
                          <div className="mt-2-5">
                            <DeleteButton
                              iclass="trash-fill"
                              buttonClass="danger"
                              clickfun={() => {
                                if (window.confirm("Delete the combination?")) {
                                  removeCombination(index);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <div className="row g-3 mb-3">
                  <div className="col-3">
                    <PrimaryButton
                      title="Add Combination"
                      clickfun={addCombination}
                      iclass="plus"
                      buttonClass='primary'
                    />
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default AddRecipe;

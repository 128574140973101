import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData,putData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { AlertError,AlertSuccess } from "../../../components/Alerts";
const LocationList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [locationData,setLocationData] = useState([])

    const [locationCounts,setLocationCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState("")

    let tablColumns = {
        id:'ID',
        title:'Title',
        labelroom:'Label Room'
    }
    const getLocationData = (pageNo, query='') =>{
        getData('/puchaselocation/list?page='+pageNo+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setLocationData(responseData.data.locations)
                setLocationCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getLocationData(pageno,search)
    }

    const filterResult = () =>{
        getLocationData(1,search)
    }
    useEffect(()=>{
        getLocationData(pageNo)
        dispatch(setPageTitle('Warehouse Locations'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Warehouse Locations',
                "link":'/locations',
            }
    ]))
    // eslint-disable-next-line
    },[dispatch])
    const updateSearch = (data) => {
        setSearchData(data)
    }
    const deleteRecord = (id) => {
        if (window.confirm("Are you sure wan't to delete. This won't undo?"))
            {
                putData('/location/delete/'+id).then((response)=>{
                    if(response.status === 200){
                        let resposnseData = response.data
                        if(resposnseData.success === false){
                            AlertError(resposnseData.message)
                            return;
                        }
                        AlertSuccess(resposnseData.message)
                        setPageNo(pageNo)
                        getLocationData(pageNo)
                    }
                })
            }
       }
     const clearFilter = () => {
        setSearchData("")
        getLocationData(1,"")
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':locationData, 'column': tablColumns, counts:locationCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/locations/' }} updateFn = {paginationUpdate} submitFilter ={filterResult}  delFun ={deleteRecord}  clearfilter={clearFilter}
                         filters={[         
                            {
                                label:"Find by Title",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            }
                        ]}
                        submenu={[
                                        {
                                            label:"Add New Location",
                                            link:'/locations/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default LocationList;
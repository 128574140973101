import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';


const RecipeList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();
    const [recipeData,setRecipeData] = useState([])

    const [recipeCounts,setRecipeCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('')
   

    let tablColumns = {
        id:'ID',
        title:'Title',
    }
    const getRecipeData = (pageNo,query='') =>{

        let url = '/recipes/list?page='+pageNo+'&query='+query;
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setRecipeData(responseData.data.recipes)
                setRecipeCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getRecipeData(pageno,search)
    }
    const filterResult = () =>{
         getRecipeData(1,search)
    }
    const clearFilter = () => {
        setSearchData("")
        getRecipeData(1,"")
        searchRef.current.value = ''
    }
    const onSearchChange = (data) => {
        setSearchData(data)
     };


    useEffect(()=>{
        dispatch(setPageTitle('Recipes'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Recipes',
                "link":'/recipes',
            }
    ]))
  setTimeout(()=>{
    getRecipeData(pageNo, search)
  },500)

    // eslint-disable-next-line
    },[dispatch])
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':recipeData, 'column': tablColumns, counts:recipeCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/recipes/' }} updateFn = {paginationUpdate}  
                        submitFilter={filterResult}
                        clearfilter={clearFilter}
                
                        filters={[         
                            {
                                label:"Find by title",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            }
                        ]}
                        
                        submenu={[
                                        {
                                            label:"Create New",
                                            link:'/recipes/add',
                                            buttonClass:'primary float-end '

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default RecipeList;
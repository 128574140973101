import React, { useState, useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm, Controller } from "react-hook-form";
import { setPageTitle } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../store/breadcrumbSlice";
import Select from "react-select";
const AddProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /*Options data*/

  const [categoryData, setCategoryData] = useState([]);
  const [itemTypeData, setItemTypeData] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedQa, setSelectedQa] = useState(0);
  /*Add Product Data*/
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const onSubmit = (data) => {
   
  //  data.location = selectedLocation;
  //  data.category = selectedCategory.value;
    data.is_qa = selectedQa;

    postData("/purchaseproducts/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/purchaseproducts");
      })
      .catch((e) => {
        AlertError(e);
      });
  };
  const categorChangeFn = (res) => {
    res ? setSelectedCategory(res) : setSelectedCategory([]);
  };

  useEffect(() => {
    /*Get Catgorires*/
    const getCategories = () => {
      getData("/puchasecategory/filter").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setCategoryData(responseData.data);
          }
        }
      });
    };
    getCategories();

    /*Get Item Types*/
    const getItemTypes = () => {
      getData("/products/subrecipes").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setItemTypeData(responseData.data);
          }
        }
      });
    };
    getItemTypes();

    /*Get Country Origin*/
    const getCountryOrigins = () => {
      getData("/puchaselocation/filter").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setLocationData(responseData.data);
          }
        }
      });
    };
    getCountryOrigins();
    dispatch(setPageTitle("Add New Product"));
    dispatch(
      setBreadcrumbs([
        {
          name: "Dashboard",
          link: "/dashboard",
        },
        {
          name: "Products",
          link: "/purchaseproducts",
        },
        {
          name: "Add New Product",
          link: "/purchaseproducts/add",
        },
      ])
    );
  }, [dispatch]);
  
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col - lg - 6 col - xl - 6 mb - 3">
                          <label
                            htmlFor="name"
                            className="col-sm-12 col-form-label"
                          >
                            Code<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="code"
                              defaultValue=""
                              {...register("code", {
                                required: true,
                                maxLength: 20,
                              })}
                            />
                            {errors?.code &&
                              errors.code.type === "required" && (
                                <span className="error">Please enter code</span>
                              )}
                            {errors?.code &&
                              errors.code.type === "maxLength" && (
                                <span className="error">
                                  Max length exceeded
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="name"
                            className="col-sm-12 col-form-label"
                          >
                            Description<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="description"
                              defaultValue=""
                              {...register("description", {
                                required: true,
                                maxLength: 125,
                              })}
                            />
                            {errors?.description &&
                              errors.description.type === "required" && (
                                <span className="error">
                                  Please enter title
                                </span>
                              )}
                            {errors?.description &&
                              errors.description.type === "maxLength" && (
                                <span className="error">
                                  Max length exceeded
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="category"
                            className="col-sm-12 col-form-label"
                          >
                            Category<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-12">
                            <Controller
                              control={control}
                              name="category"
                              rules={{ required: true }}
                              render={({  field: { onChange,value } }) => (
                                <Select
                                  options={categoryData}
                                  value={categoryData.find((c) => c.value === value)}
                                  onChange={(val) => {
                                    categorChangeFn(val)
                                    onChange(val)
                                
                                }}
                                  isSearchable
                                  isClearable
                                  placeholder={"Select Category"}
                                />
                              )}
                            />
                            {errors?.category && (
                            <span className="error">
                              Please select a category
                            </span>
                          )}
                          </div>
                          
                        </div>
                        {(selectedCategory.value === 1 ||
                          selectedCategory.value === 8) && (
                          <div className="col-lg-6 col-xl-6 mb-3">
                            <label
                              htmlFor="itemtype"
                              className="col-sm-12 col-form-label"
                            >
                              Recipe Item<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-12">
                              <select
                                id="itemtype"
                                className="form-control"
                                {...register("itemtype", {
                                  required: selectedCategory ? true : false,
                                })}
                              >
                                {itemTypeData.length &&
                                  itemTypeData.map((itemType, index) => (
                                    <option key={index} value={itemType.value}>
                                      {itemType.label}
                                    </option>
                                  ))}
                              </select>
                              {errors?.itemtype && (
                                <span className="error">
                                  Please select a recipe item
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="nominalcode"
                            className="col-sm-12 col-form-label"
                          >
                            Nominal Code<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="nominalcode"
                              defaultValue=""
                              {...register("nominalcode", { required: true, maxLength: 10 })}
                            />
                            {errors?.nominalcode && (
                              <span className="error">
                                Please enter nominal code
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="barcode"
                            className="col-sm-12 col-form-label"
                          >
                            Barcode
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="barcode"
                              defaultValue=""
                              {...register("barcode", {
                                required: false,
                                maxLength: 30,
                              })}
                            />
                            {errors?.barcode &&
                              errors.barcode.type === "required" && (
                                <span className="error">
                                  Please enter barcode
                                </span>
                              )}
                            {errors?.barcode &&
                              errors.barcode.type === "maxLength" && (
                                <span className="error">
                                  Max length exceeded
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="barcode"
                            className="col-sm-12 col-form-label"
                          >
                            Lead Time
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="leadtime"
                              defaultValue=""
                              {...register("leadtime", {
                                required: false
                              })}
                            />
                            {errors?.leadtime &&
                              errors.leadtime.type === "required" && (
                                <span className="error">
                                  Please enter leadtime
                                </span>
                              )}
                            {errors?.leadtime &&
                              errors.leadtime.type === "maxLength" && (
                                <span className="error">
                                  Max length exceeded
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="location"
                            className="col-sm-12 col-form-label"
                          >
                            Location<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-12">

                          <Controller
                              control={control}
                              name="location"
                              rules={{ required: true }}
                              render={({  field: { onChange,value } }) => (
                                <Select
                                  options={LocationData}
                                  value={LocationData.find((c) => c.value === value)}
                                  onChange={(val) => {
                                    onChange(val)
                                
                                }}
                                  isSearchable
                                  isClearable
                                  isMulti

                                  placeholder={"Select Location"}
                                />
                              )}
                            />
                            {errors?.location && (
                                <span className="error">
                                  Please select a location
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="is_qa"
                            className="col-sm-12 col-form-label"
                          >
                            Is QA Required
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="checkbox"
                              id="is_qa"
                              name="is_qa"
                              checked={selectedQa ? 1 : 0}
                              defaultValue={selectedQa ? selectedQa : 0}
                              {...register("is_qa", {
                                onChange: (res) => {
                                  if (res.target.checked) {
                                    setSelectedQa(1);
                                  } else {
                                    setSelectedQa(0);
                                  }
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary sub-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default AddProduct;

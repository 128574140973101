import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
export const Invoice = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section invoice-section">
        <div className="row">
          <div className="card-body mt-3">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row mx-2">
                    <div className="col-12" ref={ref}>
                      <div className="order-detail-col">
                        { props.orderData && (
                          <div className="grid invoice mt-3 mb-3">
                            <div className="grid-body">
                              <div className="invoice-title">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <h4>
                                      Order:#{props.orderData.sage_code}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-md-4 col-12 mb-2">
                                  <div className="billed-col">
                                    <h4>Client:</h4>
                                    <address>
                                  <b> {props.orderData.client_name} </b><br/>
                                  {props.orderData.client_address1},{props.orderData.client_address2}<br/>
                                  {props.orderData.client_county},{props.orderData.client_country}<br/>
                                  {props.orderData.client_postcode && <br/>}
                                  <i className="bi bi-person"></i> {props.orderData.client_contact_name? props.orderData.client_contact_name : 'N/A'}<br/>
                                  <i className="bi bi-phone-vibrate"></i> {props.orderData.client_contact_number? props.orderData.client_contact_number : 'N/A'}
                                  
                                </address>
                                  </div>
                                </div>
                                <div className="col-md-4 col-12 mb-2">
                                  <div className="billed-col">
                                    <h4>Ship To:</h4>
                                    <address>
                                      <b> {props.orderData.contact_name} </b>
                                      <br />
                                      {props.orderData.address_1},
                                      {props.orderData.address_2}
                                      <br />
                                      {props.orderData.county} ,{" "}
                                      {props.orderData.country}
                                      <br />
                                      {props.orderData.postcode}
                                      <br />
                                      <i className="bi bi-phone-vibrate"></i>{" "}
                                      {props.orderData.contact_number
                                        ? props.orderData.contact_number
                                        : "N/A"}
                                        <br/>
                                 GLN Code:  {props.orderData.gln_code}
                                    </address>
                                  </div>{" "}
                                </div>
                                <div className="col-md-4 col-12 mb-2">
                                  <div className="billed-col">
                                    <h4>Order Information:</h4>
                                    <address>
                                      <span>Delivery Date: </span>
                                      {props.orderData.delivery_date}
                                      <br />
                                      <span>Tax Code: </span>
                                      {props.orderData.tax_code}
                                      <br />
                                      <span>Collection Location: </span>
                                      {props.orderData.loading_place
                                        ? props.orderData.loading_place
                                        : "N/A"}
                                      <br />
                                    </address>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12 table-responsive">
                                  <h5>ORDER SUMMARY</h5>
                                  <table className="table table-striped">
                                    <thead className="order-smr">
                                      <tr>
                                        <th scope="col">
                                          <strong>#</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Code</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Partner Code</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Description</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Barcode</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Quantity</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Pallet</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="order-sum">
                                      {props.orderItems.length > 0 &&
                                        props.orderItems.map((item, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              item.item_status_id === 8
                                                ? "bg-danger text-white"
                                                : ""
                                            }
                                          >
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.product_code}</td>
                                            <td>{item.partner_code}</td>
                                            <td>{item.product_description}</td>
                                            <td>{item.barcode}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.pallet}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className="row mt-5">
                                <div className="col-md-12 table-responsive">
                                  <h5>Raw Material Information</h5>
                                  <table className="table table-striped">
                                    <thead className="order-smr">
                                      <tr className="line">
                                        <th scope="col">
                                          <strong>#</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Recipe</strong>
                                        </th>
                                        <th scope="col">
                                          <strong>Raw Material (KG)</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="order-sum">
                                      {
                                            props.orderRecipes.length > 0 &&
                                            props.orderRecipes.map((raw, rindex) => (
                                              <tr
                                                key={rindex}
                                                className={`fw-bold ${
                                                  rindex % 2 !== 0
                                                    ? "bg-warning"
                                                    : "bg-info"
                                                }`}
                                              >
                                                <td>{rindex}</td>
                                                <td>{raw.title}</td>
                                                <td>{raw.raw}</td>
                                              </tr>
                                            ))
                                        }
                                    </tbody>
                                  </table>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});

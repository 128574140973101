import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
const EditUser = ({ props }) => {
    const dispatch = useDispatch();
    /*Fetch user id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [userData, setUserData] = useState(null);
    const [roles, setRoles] = useState([]);


    /*Update User Data*/
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/users/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate('/users')
        }).catch(e => {
            AlertError(e);
        })
    }


    useEffect(() => {
        /*Get user data*/
        const getUserData = () => {
            getData('/users/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setUserData(responseData.data)
                    } else {
                        AlertError(responseData.message)
                        navigate('/users')
                    }
                }
            })
        }
       

        const getRoles = () => {
            getData('/roles').then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setRoles(responseData.data)
                    }
                    getUserData()
                }
            })
        }
        getRoles()
        dispatch(setPageTitle('Edit User'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Users',
                "link": '/users',
            },
            {
                "name": 'Edit Users',
                "link": '/users/' + id,
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">
                    <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">

                        {userData ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="name" defaultValue={userData.name}  {...register("name", {required: true , maxLength: 20})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Email<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="email" className="form-control" id="email" defaultValue={userData.email} {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
                                        {errors?.email && errors.email.type === "required" && <span className="error">Please enter email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Phone<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" defaultValue={userData.phone} {...register("phone", {required: true , maxLength: 10})} />
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address" className="col-sm-12 col-form-label">Address</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address" defaultValue={userData.address} {...register("address", {required: false , maxLength: 30})} />
                                        {errors?.address && <span className="error">Please enter Address</span>}
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="role" className="col-sm-12 col-form-label">Role<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="role" className="form-control" disabled={true} defaultValue={userData.roleid}>
                                        <option key={0} value={0}>Select a role</option>
                                            {roles.length && roles.map((role, index) => (
                                            <option key={index} value={role.id}>{role.role}</option>
                                        ))}
                                        </select>
                                        {errors?.role && <span className="error">Please select a role</span>}
                                    </div>
                                  
                                </div>
                                { userData.id !== 1 ?
                                <div className="col-lg-6 col-xl-6 mb-3" >
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={userData.status} {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>:<input type="hidden" value={1} {...register("status", {required: true})} id ="status" /> }
                                <hr />  

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="password" className="col-sm-12 col-form-label">New Password</label>
                                    <div className="col-sm-12">
                                        <input type="password" className="form-control" id="password"  {...register("password",{maxLength:20})}/>
                                        {errors?.password && errors.password.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>              
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="confirmPassword" className="col-sm-12 col-form-label">Confirm Password </label>
                                    <div className="col-sm-12">
                                        <input type="password" className="form-control" id="confirmPassword" {...register("confirmPassword",{
                                            required: false,
                                            maxLength:20,
                                            validate: (val) => {
                                                if(watch('password') && !val){
                                                    return "Please enter confirm password";
                                                }
                                                
                                                if (watch('password') !== val) {
                                                  return "Your passwords do no match";
                                                }
                                              }
                                        })} />
                                        {errors?.confirmPassword  && <span className="error">{errors.confirmPassword.message}</span>}
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div></div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                          </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditUser;
import React, { useEffect, useState ,useRef} from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData,putData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { AlertError,AlertSuccess } from "../../../components/Alerts";
const CategoryList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [categoryData,setCategoryData] = useState([])

    const [categoryCounts,setCategoryCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState("")

    let tablColumns = {
        id:'ID',
        title:'Title',
        description:'Description'
    }
    const getCategoryData = (pageNo, query='') =>{
        getData('/puchasecategory/list?page='+pageNo+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setCategoryData(responseData.data.categories)
                setCategoryCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getCategoryData(pageno,search)
    }
    const filterResult = () =>{
        getCategoryData(1,search)
    }
    useEffect(()=>{
        getCategoryData(pageNo)
        dispatch(setPageTitle('Categories'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Categories',
                "link":'/category',
            }
    ]))
     // eslint-disable-next-line
    },[dispatch])
    const updateSearch = (data) => {
        setSearchData(data)
    }
    const clearFilter = () => {
        setSearchData("")
        getCategoryData(1,"")
        searchRef.current.value = ''
    }
    const deleteRecord = (id) => {
        if (window.confirm("Are you sure wan't to delete. This won't undo?"))
            {
                putData('/category/delete/'+id).then((response)=>{
                    if(response.status === 200){
                        let resposnseData = response.data
                        if(resposnseData.success === false){
                            AlertError(resposnseData.message)
                            return;
                        }
                        AlertSuccess(resposnseData.message)
                        setPageNo(pageNo)
                        getCategoryData(pageNo)
                    }
                })
            }
       }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':categoryData, 'column': tablColumns, counts:categoryCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/category/' }} updateFn = {paginationUpdate} submitFilter ={filterResult} delFun ={deleteRecord}  clearfilter={clearFilter}
                         filters={[         
                            {
                                label:"Find by Title",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            }
                        ]}  submenu={[
                                        {
                                            label:"Add New Category",
                                            link:'/category/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default CategoryList;
import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
export const Printplan = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section invoice-section">
        <div className="row">
          <div className="card-body mt-3">
            <div className="card">
              <div className="card-body">
              <table className="table table-striped table-bordered" ref={ref}>
                <thead>
                  <tr>
                    <th scope="row">Lines</th>
                    <th scope="row"></th>
                    <th>Product</th>
                    <th>WGT</th>
                    <th>Size</th>
                    <th></th>
                    <th>Order</th>
                    <th>Prod.</th>
                    <th></th>
                    <th>Packs</th>
                    <th>P+</th>
                    <th>Use by</th>
                    {props.planTypeData.map((type, index) => (
                      <th key={"t_" + index}>{type}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {props.planProductsData.length > 0 && (
                   props.planProductsData.map((product, index) => (
                      <React.Fragment key={"p_" + index}>
                        <tr className="text-white bg-primary">
                          <th scope="row" className="text-white text-center">
                            Line
                          </th>
                          <td
                            className="text-white bg-primary"
                            colSpan={12 + Object.keys(props.planTypeData).length}
                          >
                            {product.client}
                          </td>
                        </tr>
                        {product.items.map((item, pindex) => (
                          <tr key={"i_" + pindex} className={item.isforce ? 'bg-warning' : '' }>
                            <td>
                            {item.line.length > 0 && item.line.map((line) => (
                                    line.value
                                )).join(', ')
                            }
                            </td>
                            <td>{item.type}</td>
                            <td>
                                {item.product_description}
                              </td>
                            <td>{item.weight}</td>
                            <td>{parseInt(item.size)}</td>
                            <td>{item.produced}</td>
                            <td>{item.quantity}</td>
                            <td>{item.inproduction}</td>
                            <td>{item.extraproduction}</td>
                            <td>{item.packs}</td>
                            <td>{item.p_plus}</td>
                            <td>{item.use_by}</td>

                            {props.planTypeData.map((type, ipndex) => (
                              <td key={ipndex}>
                                {item.recipes[type]
                                  ? Number(item.recipes[type].raw).toFixed(2)
                                  : ""}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});

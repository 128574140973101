import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
export const Invoice = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section invoice-section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-3">
              <div className="row mx-2">
                <div className="col-12" ref={ref}>
                  <div className="order-detail-col">
                    {props.orderData && (
                      <div className="grid invoice mt-3 mb-3">
                        <div className="grid-body">
                          <div className="invoice-title">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <h4>Order #{props.orderData.sage_po_code}</h4>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-md-5 col-12 mb-2">
                              <div className="billed-col">
                                <h6 className="fw-bold">Billed To:</h6>
                                <address>
                                  <b> {props.orderData.supplier_name} </b>
                                  <br />
                                  {props.orderData.supplier_address1},
                                  {props.orderData.supplier_address2}
                                  <br />
                                  {props.orderData.supplier_city} ,{" "}
                                  {props.orderData.supplier_county}
                                  <br />
                                  {props.orderData.supplier_postcode}
                                  <br />
                                  <i className="bi bi-person-badge"></i>{" "}
                                  {props.orderData.supplier_contact_name
                                    ? props.orderData.supplier_contact_name
                                    : "N/A"}{" "}
                                  <br />
                                  <i className="bi bi-phone-vibrate"></i>{" "}
                                  {props.orderData.supplier_contact_number
                                    ? props.orderData.supplier_contact_number
                                    : "N/A"}
                                </address>
                              </div>{" "}
                            </div>
                            <div className="col-md-5 col-12 text-left offset-md-2 mb-2">
                              <div className="billed-col">
                                <h6 className="fw-bold">Order Information:</h6>
                                <address>
                                  <span>Delivery Date: </span>
                                  {props.orderData.delivery_date}
                                  <br />
                                  <span>Tax Code: </span>
                                  {props.orderData.tax_code}
                                  <br />
                                  <span>Commodity Code: </span>
                                  {props.orderData.commoditycode}
                                  <br />
                                  <span>Country of Origin: </span>
                                  {props.orderData.country_of_origin}
                                  <br />
                                  <span>Delivery method / haulier: </span>
                                  {props.orderData.haulier}
                                  <br />
                                  <span>Collection Location: </span>
                                  {props.orderData.loading_place}
                                  <br />
                                </address>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-12 table-responsive">
                              <h4>ORDER SUMMARY</h4>
                              <table className="table table-striped">
                                <thead className="order-smr">
                                  <tr className="line">
                                    <th scope="col">
                                      <strong>#</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>Description</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>Quantity</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>Unit Quantity</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>Total Quantity</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>Price</strong>
                                    </th>
                                    <th scope="col">
                                      <strong>VAT</strong>
                                    </th>
                                    <th scope="col" className="text-right">
                                      <strong>Total</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="order-sum">
                                  {props.orderItems.length > 0 &&
                                    props.orderItems.map((item, index) => (
                                      <React.Fragment key={index}>
                                        <tr
                                          className={
                                            item.item_status_id === 7
                                              ? "bg-danger text-white"
                                              : ""
                                          }
                                        >
                                          <th scope="row">{index + 1}</th>
                                          <td>{item.goods_description}</td>
                                          <td>
                                            {item.quantity}
                                            <small className="text-wrap">{item.unit}</small>
                                          </td>
                                          <td>{item.unit_quantity}</td>
                                          <td>
                                            {item.quantity * item.unit_quantity}{item.unit}
                                          </td>
                                          <td>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                props.orderData.currency_symbol,
                                              }}
                                            />
                                            {item.price}
                                          </td>
                                          <td>
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                 props.orderData.currency_symbol,
                                              }}
                                            />
                                            {item.tax_amount}
                                          </td>
                                          <td className="text-right">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                props.orderData.currency_symbol,
                                              }}
                                            />
                                            {item.total_amount}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                  <tr className="mt-5">
                                    <td colSpan={4}></td>
                                    <td className="text-right" colSpan={3}>
                                      SubTotal
                                    </td>
                                    <td className="text-right">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            props.orderData.currency_symbol,
                                        }}
                                      />
                                      { props.orderData.subtotal}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4}></td>
                                    <td className="text-right" colSpan={3}>
                                      Taxes ({props.orderData.vat_rate}%)
                                    </td>
                                    <td className="text-right">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            props.orderData.currency_symbol,
                                        }}
                                      />
                                      {props.orderData.vattotal}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4}></td>
                                    <td className="text-right" colSpan={3}>
                                      Delivery charge (if applicable):
                                    </td>
                                    <td className="text-right">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            props.orderData.currency_symbol,
                                        }}
                                      />
                                      {props.orderData.delivery_charge}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4}></td>
                                    <td className="text-right" colSpan={3}>
                                      Commissions / Surcharges (if applicable)
                                    </td>
                                    <td className="text-right">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            props.orderData.currency_symbol,
                                        }}
                                      />
                                      {props.orderData.commissions_surcharges}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4}></td>
                                    <td
                                      className="text-right total-col"
                                      colSpan={3}
                                    >
                                      <strong>Total</strong>
                                    </td>
                                    <td className="text-right  total-col">
                                      <strong>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              props.orderData.currency_symbol,
                                          }}
                                        />
                                        {props.orderData.total}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});

import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../store/breadcrumbSlice";
import { setPageTitle } from "../../store/resourceSlice";
import { getData } from "../../services/HttpServices";
import { PrimaryButton } from "../../components/Button";
import AddLinePopUp from "./addLinePopUp";
import EditItemLine from "./EditItemLine";
import Debulk from "./debulkPopUp";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import SelectOptions from "../../components/SelectOptions";
import { Printplan } from "./printplan";
import { useReactToPrint } from 'react-to-print';
const Daliyproductionplan = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [planProductsData, setPlanProductsData] = useState([]);
  const [planTypeData, setPlanTypeData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [showAddLine, setShowAddLine] = useState(false);
  const [showEditItem, setShowEditItem] = useState(false);
  const [showDebulk, setShowDebulk] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedLine, setSelectedLine] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const [currentLine, setCurrentLine] = useState([]);
  const [editLines, setEditLines] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [history, setHistory] = useState([]);

  const getproductPlan = () => {
    let newDate = "";
    if (currentDate) newDate = currentDate.toLocaleDateString("en-UK");
    

    let line = 0;
    if (selectedLine && selectedLine.value) line = selectedLine.value;
    let client = 0;
    if (selectedClient && selectedClient.value) client = selectedClient.value;

    getData(
      "/productionplan?date=" + newDate + "&line=" + line + "&client=" + client
    ).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.success) {
          setPlanProductsData(responseData.data.products);
          setPlanTypeData(responseData.data.types);
          setHistory(responseData.data.history);
        }
      }
    });
  };
  const hideLine = () => {
    setShowAddLine(false);
  }; 
  
 
  useEffect(() => {
   
    dispatch(setPageTitle("Daliy Production Plan"));
    dispatch(
      setBreadcrumbs([
        {
          name: "Dashboard",
          link: "/dashboard",
        },
        {
          name: "Daily Production Plan",
          link: "/daliyproductionplan",
        },
      ])
    );
    getData("/clients/filter").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setClientData(responseData.data);
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const showLineHandler = (id, index, pindex) => {
    setCurrentLine(planProductsData[index]["items"][pindex]["line"]);
    setSelectedId(id);
    setShowAddLine(true);
  };
  const onDateRangeChange = (date) => {
    if (date) {
      setCurrentDate(date);
      
    }
  };
  const showDebulkHandler = () => {
    setShowDebulk(true);
  };
  const hideDebulkHandler = () => {
    setShowDebulk(false);
  };
  const Lines = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ];
  const changeLineNo = (res) => {
    res ? setSelectedLine(res) : setSelectedLine({});
  };
  const submitFilter = () => {
    getproductPlan();
    let newDate = new Date().toLocaleDateString("en-UK");
    let compareDate = currentDate.toLocaleDateString("en-UK");

   (newDate === compareDate) ? setShowEdit(true) : setShowEdit(false)
  };
  const cancelFilter = () => {
    setSelectedClient({});
    setCurrentLine({});
    getproductPlan();
  };
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });
  const showEditHandler = (id, index,pindex) => {
    setEditLines(planProductsData[index]["items"][pindex]);
    setSelectedId(id);
    setShowEditItem(true);
  };
  const hideEditHandler = () => {
    setSelectedId(0);
    setShowEditItem(false);
    getproductPlan()
  };
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-2">
              <div className="row">
                <div className="col-12 btn-add-div">
                  {/* filters */}
                  <div className="group_filter">
                    <div className="datatable-top float-start mx-2">
                      <div className="datatable-search">
                        <DatePicker
                          className="form-control "
                          selected={currentDate}
                          onChange={onDateRangeChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Click to select a date"
                          monthsShown={1}
                        />
                      </div>
                    </div>
                    <div className="datatable-top float-start mx-2">
                      <div className="datatable-search">
                        <SelectOptions
                          options={Lines}
                          default={selectedLine}
                          changeFn={changeLineNo}
                          isClearable={true}
                          placeholder={"Line No"}
                        />
                      </div>
                    </div>
                    <div className="datatable-top float-start mx-2">
                      <div className="datatable-search">
                        <SelectOptions
                          options={clientData}
                          default={selectedClient}
                          changeFn={(res) => {
                            setSelectedClient(res);
                          }}
                          isClearable={true}
                          placeholder={"Client"}
                        />
                      </div>
                      <React.Fragment>
                        <PrimaryButton
                          buttonText="Filter"
                          clickfun={submitFilter}
                          buttonClass="primary view-btn float-start"
                          iclass="search"
                        />
                        <PrimaryButton
                          buttonText="Clear"
                          clickfun={cancelFilter}
                          buttonClass="primary view-btn float-start"
                          iclass="clear"
                        />
                      </React.Fragment>
                    </div>
                    <div className="group_submenu">
                      {planProductsData.length > 0 && (
                        <React.Fragment>
                          <PrimaryButton
                            buttonText="Debulk Requests"
                            clickfun={showDebulkHandler}
                            buttonClass="primary float-end  view-btn "
                            iclass={"disc"}
                          />
                          <PrimaryButton
                            buttonText="Print PDF"
                            clickfun={handlePrint}
                            buttonClass="primary float-end  view-btn "
                            iclass={"filetype-pdf"}
                          />
                          <div className={"hide"}>
                            <Printplan
                              ref={componentRef}
                              planProductsData={planProductsData}
                              planTypeData={planTypeData}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="row"></th>
                      <th scope="row"></th>
                      <th>Product</th>
                      <th>WGT</th>
                      <th>Size</th>
                      <th></th>
                      <th>Order</th>
                      <th>Prod.</th>
                      <th></th>
                      <th>Packs</th>
                      <th>P+</th>
                      <th>Use by</th>
                      {planTypeData.map((type, index) => (
                        <th key={"t_" + index}>{type}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {planProductsData.length > 0 ? (
                      planProductsData.map((product, index) => (
                        <React.Fragment key={"p_" + index}>
                          <tr className="text-white bg-primary">
                            <th scope="row" className="text-white text-center">
                              Line
                            </th>
                            <td
                              className="text-white bg-primary"
                              colSpan={12 + Object.keys(planTypeData).length}
                            >
                              {product.client}
                            </td>
                          </tr>
                          {product.items.map((item, pindex) => (
                            <tr
                              key={"i_" + pindex}
                              className={item.isforce ? "bg-warning" : ""}
                            >
                              <td>
                                <PrimaryButton
                                  buttonClass="primary"
                                  iclass="line"
                                  clickfun={() =>
                                    showLineHandler(item.id, index, pindex)
                                  }
                                  title={"Add Line"}
                                />
                               {showEdit &&
                               
                               <PrimaryButton
                                  buttonClass="primary"
                                  iclass="pencil-fill"
                                  clickfun={() =>
                                    showEditHandler(item.id,index, pindex)
                                  }
                                  title={"Edit"}
                                />}
                              </td>
                              <td>{item.type}</td>
                              <td>{item.product_description}</td>
                              <td>{item.weight}</td>
                              <td>{parseInt(item.size)}</td>
                              <td>{item.produced}</td>
                              <td>{item.quantity}</td>
                              <td>{item.inproduction}</td>
                              <td>{item.extraproduction}</td>
                              <td>{item.packs}</td>
                              <td>{item.p_plus}</td>
                              <td>{item.use_by}</td>

                              {planTypeData.map((type, ipndex) => (
                                <td key={ipndex}>
                                  {item.recipes[type]
                                    ? Number(item.recipes[type].raw).toFixed(2)
                                    : ""}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr key={"e1"}>
                        <td colSpan={12}>
                          <div className="alert alert-warning text-center">
                            {" "}
                            No production plan for today
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {history.length > 0 && (
          <div className="row">
            <div className="card">
              <div className="card-body mt-3">
                <h5>History</h5>
              </div>
              <div className="card-body mt-3">
                <ul className="notification">
                  {history.map((info, hIndex) => (
                    <li key={hIndex}>
                      <div className="notification-time">
                        <span className="date fw-bold"> {info.date}</span>
                        <span className="time ms-2 "> {info.time}</span>
                      </div>
                      <div className="notification-icon">
                        <span></span>
                      </div>
                      <div className="notification-time-date mb-2 d-block d-md-none">
                        <span className="date fw-bold"> {info.date}</span>
                        <span className="time ms-2 "> {info.time}</span>
                      </div>
                      <div className="notification-body">
                        <div className="media mt-0">
                          <div className="media-body ms-3 d-flex">
                            <div className="">
                              {info.force && (
                                <p className="tx-14 text-dark mb-2 tx-semibold fw-bold">
                                  {" "}
                                  <b>{info.user_name}</b> has added a SO (
                                  <Link
                                    to={"/orders/details/" + info.order_id}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    #{info.order_id}
                                  </Link>
                                  ) of {info.client} to today's production (
                                  {info.date})
                                </p>
                              )}
                              {!info.force && (
                                <p className="tx-14 text-dark mb-2 tx-semibold fw-bold">
                                  {" "}
                                  <b>{info.user_name}</b> updated lines{" "}
                                  {info.lines} to {info.description} product for
                                  client {info.client}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>
      <AddLinePopUp
        current={currentLine}
        show={showAddLine}
        id={selectedId}
        hideFn={hideLine}
      />
       <EditItemLine
        id={selectedId}
        show={showEditItem}
        lines={editLines}
        hideFn={hideEditHandler}
      />
      <Debulk
        show={showDebulk}
        currentDate={currentDate}
        hideFn={hideDebulkHandler}
      />
    </DefaultLayout>
  );
};
export default Daliyproductionplan;

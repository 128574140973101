import React, { useEffect, useState } from "react"
import { postData } from "../services/HttpServices";
import { Link, Navigate ,useNavigate } from "react-router-dom";
import { AlertError, AlertSuccess } from "./Alerts";
import { useSelector } from 'react-redux';
const Header = () => {
    const navigate = useNavigate();
    const currentData = useSelector((state) => state.currentData);
    /*Inital Stage*/
    const [isActive, setIsActive] = useState("");
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [userImage, setUserImage] = useState("/images/no-image.jpg");

    /*Show/Hide Menu Bar*/
    const hideMenuBar = () => {
        if (isActive) {
            document.body.classList.remove('toggle-sidebar')
            setIsActive("")
        } else {
            document.body.classList.add('toggle-sidebar')
            setIsActive("toggle-sidebar")
        }
    }
    /*Set user state logout*/
    const logOut = () => {
        postData('/logout', {}).then((response) => {
            if (response.status === 200) {
                let data = response.data
                if (data.success) {
                    localStorage.removeItem('iq')
                    AlertSuccess(data.message)
                    return navigate('/')
                }
                AlertError(data.message)
            } else {
                AlertError(response.message)
            }
        })
    }
    /*On DoM Load*/
    useEffect(() => {
        if (!currentData.profile) {
            return <Navigate to="/" />
        }
        setUserName(currentData.profile.name)
        setUserRole(currentData.profile.userrole)
        setUserImage(process.env.REACT_APP_IMAGE_URL + currentData.profile.picture)
    }, [currentData]);
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
    <div className="d-flex align-items-center justify-content-between logo-arrow">
        <Link to={"/dashboard" } className="logo d-flex align-items-center">
            <span className="d-none d-lg-block mx-3">
                <img src="/images/banner-logo.png" alt=""  />
            </span>
        </Link>
          <span className="mobile-nav">
            <img className="toggle-sidebar-btn" onClick={hideMenuBar} src="/images/menu.png" alt="" />
        </span>
        <span className="toggle-sidebar-arrow">
            <img className="toggle-sidebar-btn" onClick={hideMenuBar} src="/images/slide-arrow.svg" alt="" />
        </span>
    </div>
    <nav className="header-nav">
        <div className="row">
            <div className="col-lg-6 col-xs-8">
                <div className="pagetitle">
                    <h1>{currentData.pageTitle}</h1> 
                    
                </div>
            </div>
            <div className="col-lg-6 text-end col-xs-8">
                <ul className="d-flex align-items-center float-end mb-0">
                    <li className="nav-item dropdown pe-3 user-col">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="/dashboard" data-bs-toggle="dropdown">
                            <img src={userImage} alt="Profile" className="rounded-circle" />
                            <span className="d-none d-md-block dropdown-toggle ps-2"></span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                            {userName ? (
                            <>
                                <h6>{userName}</h6>
                                <span>{userRole}</span>
                            </>
                            ) : (
                                <>
                                   <h6>Anonymous</h6>
                                    <span>---</span>
                                 </>
                            )}
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <Link className="dropdown-item d-flex align-items-center" to={"/profile"}>
                                    <i className="bi bi-person"></i>
                                    <span>My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <a className="dropdown-item d-flex align-items-center" onClick={logOut} href="#/">
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Sign Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
    )
}
export default Header;
import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData,putData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { AlertError,AlertSuccess } from "../../../components/Alerts";
const ProductList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [productData,setProductData] = useState([])

    const [productCounts,setProductCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [categories,setCategoryData] = useState([])
    const [locations,setLocationData] = useState([])
    const [recipes,setRecipe] = useState([])
    const [search,setSearchData] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRecipe, setSelectedRecipe] = useState(null);

    let tablColumns = {
        id:'ID',
        product_code:'Code',
        description:'Description',
        categoroy_title:'Categoroy Title',
        item_type:'Recipe',
        location:'Location'
    }
    const getProductData = (pageNo, query='',categories='', location='', recipe='') =>{
        let url = '/purchaseproducts/list?page='+pageNo+'&query='+query
        if(categories){
            url +='&categories='+categories.value
        }
        if(location){
            url +='&location='+location.value
        }
        if(recipe){
            url +='&recipe='+recipe.value
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data.products)
                setProductCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getProductData(pageno,search,selectedCategory,selectedLocation,selectedRecipe)
    }
    useEffect(()=>{
        

    getData('/puchasecategory/filter').then((response)=>{
        if(response.status === 200){
            let responseData = response.data
            setCategoryData(responseData.data)
        }
    })
    getData('/puchaselocation/filter').then((response)=>{
        if(response.status === 200){
            let responseData = response.data
            setLocationData(responseData.data)
        }
    })
    getData('/purchaseproducts/filterrecipe').then((response)=>{
        if(response.status === 200){
            let responseData = response.data
            setRecipe(responseData.data)
        }
    })

    getProductData(pageNo)
    dispatch(setPageTitle('Products'))
    dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Products',
                "link":'/purchaseproducts',
            }
    ]))
     // eslint-disable-next-line
    },[dispatch])
    const onSearchChange = (data) => {
        setSearchData(data)
     };
     const changeCategory = (data)=>{
         setSelectedCategory(data)
     }
 
     const changeLocation = (data)=>{
         setSelectedLocation(data)
     }
     const changeRecipe = (data)=>{
         setSelectedRecipe(data)
     }
 
    
      const submitFilter = () => {
          getProductData(1,search,selectedCategory,selectedLocation,selectedRecipe)
      }
      const clearFilter = () => {
        searchRef.current.value = ''
        getProductData(1)
        setSelectedCategory(null)
        setSelectedLocation(null)
        setSelectedRecipe(null)
        setSearchData("")
    }
    const deleteRecord = (id) => {
        if (window.confirm("Are you sure wan't to delete. This won't undo?"))
            {
                putData('/purchaseproducts/delete/'+id).then((response)=>{
                    if(response.status === 200){
                        let resposnseData = response.data
                        if(resposnseData.success === false){
                            AlertError(resposnseData.message)
                            return;
                        }
                        AlertSuccess(resposnseData.message)
                        setPageNo(pageNo)
                        getProductData(pageNo,search,selectedCategory,selectedLocation)
                    }
       })
            }
       }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':productData, 'column': tablColumns, counts:productCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/purchaseproducts/' }} updateFn = {paginationUpdate} submitFilter={submitFilter} clearfilter={clearFilter}  delFun ={deleteRecord} 
                        filters={[         
                            {
                                label:"Find by Code,Description",
                                type:'search',
                                class:'form-control',
                                searchFilterFn:onSearchChange,
                                default:search,
                                ref:searchRef,
                            },{
                                label:"Select Category",
                                type:"select",
                                class:'form-control',
                                options:categories,
                                default:selectedCategory,
                                changeOptionsFn:changeCategory
                            },{
                                label:"Select Lcoation",
                                type:"select",
                                class:'form-control',
                                options:locations,
                                default:selectedLocation,
                                changeOptionsFn:changeLocation
                            },{
                                label:"Select Recipe",
                                type:"select",
                                class:'form-control',
                                options:recipes,
                                default:selectedRecipe,
                                changeOptionsFn:changeRecipe
                            }
                        ]}
                        
                        
                        submenu={[
                                        {
                                            label:"Add New",
                                            link:'/purchaseproducts/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default ProductList;
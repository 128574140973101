import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { postData, getData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const AddClient = ({ props }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [taxCodeData, setTaxCodeData] = useState([])
    const [currency, setCurrency] = useState([])
    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/clients/add', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/clients");
        }).catch(e => {
            AlertError(e);
        })
    }
    useEffect(() => {
        const getTaxCodes = () => {
            getData("/taxcodes/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setTaxCodeData(responseData.data);
                    }
                }
            });
        };
        getTaxCodes();
        const getCurrency = () => {
            getData("/currency").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setCurrency(responseData.data);
                    }
                }
            });
        };
        getCurrency();
        dispatch(setPageTitle('Add New Client'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name":'Client Address',
                "link":'/clients/addresslist',
            },
            {
                "name": 'Clients',
                "link": '/clients',
            },
            {
                "name": 'Add New Client',
                "link": '/clients/add',
            }
        ]))
    }, [dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                          <div className="card-body">
                            <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                       <div className="row">
                               <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="code" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue=''  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="name" defaultValue=''  {...register("name", {required: true , maxLength: 128})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="email" className="col-sm-12 col-form-label">Email</label>
                                    <div className="col-sm-12">
                                        <input type="email" className="form-control" id="email" defaultValue='' {...register("email", {required: false, pattern: /^\S+@\S+$/i})} />
                                        {errors?.email && errors.name.type === "required" && <span className="error">Please enter email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Phone</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="phone" defaultValue='' {...register("phone", {required: false , maxLength: 20})} />
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactname" className="col-sm-12 col-form-label">Contact Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactname" defaultValue='' {...register("contactname", {required: true , maxLength: 20})} />
                                        {errors?.contactname && errors.contactname.type === "required" && <span className="error">Please enter contact name</span>}
                                        {errors?.contactname && errors.contactname.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="contactnumber" className="col-sm-12 col-form-label">Contact Number<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="contactnumber" defaultValue='' {...register("contactnumber", {required: true , maxLength: 20})} />
                                        {errors?.contactnumber && errors.contactnumber.type === "required" && <span className="error">Please enter contact number</span>}
                                        {errors?.contactnumber && errors.contactnumber.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 1<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address_1" defaultValue=''   {...register("address_1", {required: true , maxLength: 128})} />

                                        {errors?.address_1 && errors.address_1.type === "required" && <span className="error">Please enter address 1</span>}
                                        {errors?.address_1 && errors.address_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="address_1" className="col-sm-12 col-form-label">Address 2</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="address_2" defaultValue='' {...register("address_2", {required: false, maxLength: 128})} />
                                        {errors?.address_2 && errors.address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="city" className="col-sm-12 col-form-label">City<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="city" defaultValue='' {...register("city", {required: true , maxLength: 20})} />
                                        
                                        {errors?.city && errors.city.type === "required" && <span className="error">Please enter city</span>}
                                        {errors?.city && errors.city.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="county" className="col-sm-12 col-form-label">County<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="county" defaultValue='' {...register("county", {required: true , maxLength: 20})} />
                                        {errors?.county && errors.county.type === "required" && <span className="error">Please enter county</span>}
                                        {errors?.county && errors.county.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Post Code</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="postcode" defaultValue='' {...register("postcode", {required: false , maxLength: 10})} />
                                        {errors?.postcode && errors.postcode.type === "required" && <span className="error">Please enter post code</span>}
                                        {errors?.postcode && errors.postcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Tax Code<span className="text-danger">*</span>
                                    <small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="The selected tax code will be applied to the purchase order, which will be created under this Client."></i></small>
                                    </label>
                                    <div className="col-sm-12">
                                    
                                       <select id="taxcode" className="form-control" defaultValue={0} {...register("taxcode", {required: true })}>
                                       <option key={0} value={0}>Select a tax code</option>
                                            {taxCodeData.length && taxCodeData.map((taxCode, index) => (
                                            <option key={index} value={taxCode.value}>{taxCode.label}</option>
                                        ))}
                                         {errors?.category && <span className="error">Please select a tax code</span>}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Currency<span className="text-danger">*</span> 
                                    <small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="The selected currency will be applied to the purchase order, which will be created under this Client."></i></small> 
                                    </label>
                                    <div className="col-sm-12">
                                    
                                       <select id="currency" className="form-control" defaultValue={0} {...register("currency", {required: true })}>
                                       <option key={0} value={0}>Select a currency</option>
                                            {currency.length && currency.map((c, index) => (
                                            <option key={index} value={c.value}>{c.label}</option>
                                        ))}
                                         {errors?.currency && <span className="error">Please select a currency</span>}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="status" className="col-sm-12 col-form-label">Status<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="status" className="form-control" defaultValue={1} {...register("status", {required: true})}>
                                            <option value={1}>Active</option>
                                            <option value={2}>In-Active</option>
                                        </select>
                                        {errors?.status && <span className="error">Please select a status</span>}
                                    </div>
                                   
                                </div>    
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                     </div>
                            </form>
                        </div>
                        </div>
                        </div>
                         </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default AddClient;
import { Link } from "react-router-dom";

export function PrimaryButton({
    buttonText,title,buttonClass, clickfun , iclass, disabled=false}) {
      return (
          <button className={`btn btn-${buttonClass ? buttonClass : ""} btn-sm mx-2 `}  type="button" onClick={clickfun} title={title} disabled={disabled}>
              <i className={`bi bi-${iclass ? iclass : "menu-button-fill"}`}></i> {buttonText}
          </button>
      )
}

export function EditButton({
    buttonText,title, buttonClass, link , iclass}) {
      return (
          <Link className={`btn ${buttonClass ? buttonClass : ""} btn-sm mx-2`}  to={link} title={title}>
             <i className={`bi bi-${iclass ? iclass : "pencil-square"}`}></i>{buttonText}
          </Link>
      )
}


export function DeleteButton({
    buttonText,title, buttonClass, clickfun , iclass, id}) {
        
      return (
          <button className={`btn ${buttonClass ? buttonClass : ""} btn-sm`} type="button"  onClick={() => clickfun(id)} title={title}>
             <i className={`bi bi-${iclass ? iclass : "-square"}`}></i>{buttonText}
          </button>
      )
}

export function AddButton({
    buttonText, title,buttonClass, link , iclass}) {
      return (
          <Link className={`btn btn-${buttonClass ? buttonClass : "primary"} btn-sm mx-2 view-btn`}  to={link} title={title}>
             <i className={`bi bi-${iclass ? iclass : "patch-plus-fill"}`}></i> {buttonText}
          </Link>
      )
}
export function BackButton({
    buttonText,title, buttonClass, link , iclass}) {
      return (
          <Link className={`btn btn-${buttonClass ? buttonClass : "secondary"} btn-sm mx-2`}  to={link} title={title}>
             <i className={`bi bi-${iclass ? iclass : "backspace-fill"}`}></i> {buttonText}
          </Link>
      )
}
import React, { useState, useEffect } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { AlertError } from "../../components/Alerts";
import DatePicker from "react-datepicker";
import { setPageTitle  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
import { useForm, Controller } from 'react-hook-form';
const Rawoutput = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { handleSubmit, formState: { errors }, control } = useForm();
  const onSubmit = () => {
        let exportstartDate= startDate.toLocaleDateString("en-UK")
        let exportendDate= endDate.toLocaleDateString("en-UK")
        getData("/reports/rawexport?start=" + exportstartDate+"&end=" + exportendDate).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if(responseData.success){
                    DownloadZip(responseData.data.folder);
                    return;
                }
                AlertError(responseData.message)
            }
        }).catch(e=>{
            AlertError(e);

        });
  };

 const DownloadZip = (fileURL) => {
    window.open(
        process.env.REACT_APP_IMAGE_URL + fileURL + "&type=zip",
        "_blank",
        "noreferrer"
    );
    return;
 };                       
  useEffect(() => {
      dispatch(setPageTitle('Raw Fruits Reports'))
      dispatch(setBreadcrumbs([
        {
        "name":'Dashboard',
        "link":'/dashboard',
        },
        {
            "name":'Raw Fruits Reports',
            "link":'/rawfruits',
        }
    ]))
  }, [dispatch]);

  return (
    <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body mt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
                         <div className="row mb-3">
                          <label htmlFor="reportdate" className="col-3 col-form-label">Start Date<span className="text-danger">*</span></label>
                            <div className="col-4">

                            <Controller
                              control={control}
                              name="startdate"
                              rules={{ required: true }}
                              render={({  field: { onChange } }) => (
                                <DatePicker
                                className="form-control col-6"
                                selected={startDate}
                                id="startdate"
                                onChange={(date) => {
                                  setStartDate(date)
                                  onChange(date)
                                }}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Click to select a date"
                                monthsShown={1}
                                maxDate={new Date()}
                                isClearable={startDate}
                                
                              />
                              )}
                            />
                            {errors?.startdate && (
                            <span className="error">
                             Please choose start date
                            </span>
                          )}
                              </div>
                                   
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="reportdate" className="col-3 col-form-label">End Date<span className="text-danger">*</span></label>
                            <div className="col-4">

                            <Controller
                              control={control}
                              name="enddate"
                              rules={{ required: true }}
                              render={({  field: { onChange } }) => (
                                <DatePicker
                                className="form-control col-6"
                                selected={endDate}
                                id="enddate"
                                onChange={(date) => {
                                  setEndDate(date)
                                  onChange(date)
                                }}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Click to select a date"
                                monthsShown={1}
                                minDate={startDate}
                                isClearable={endDate}
                                
                              />
                              )}
                            />
                            {errors?.enddate && (
                            <span className="error">
                             Please choose end date
                            </span>
                          )}
                              </div>
                                   
                         </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default Rawoutput;

import { getData } from '../services/HttpServices';
const { createSlice,createAsyncThunk } = require('@reduxjs/toolkit');
const simplyFruitsSlice = createSlice({
    name: 'simplyfruits',
    initialState: {
        profile: [],
        pageTitle:''
    },
    reducers: {
        setPageTitle(state, action) {
            document.title = 'SimplyFruit | '+action.payload
            state.pageTitle = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentLogin.pending, (state, action) => {
                //console.log('Pending Thunk')
            })
            .addCase(fetchCurrentLogin.fulfilled, (state, action) => {
                state.profile = action.payload
            })
            .addCase(fetchCurrentLogin.rejected, (state, action) => {
               // console.log('Rejected Thunk')
            })
    }
})

export const fetchCurrentLogin = createAsyncThunk('resources/fetch', async (state, action) => {
    try {
       return getData('/currentloggedin').then((response)=>{
        let resposnseData = response.data
        if(resposnseData.success){
            return resposnseData.data
        }
        })
    } catch (err) {
        //console.log(err)
    }
})

export const { setPageTitle } = simplyFruitsSlice.actions;
export default simplyFruitsSlice.reducer;
import React from "react"
const Footer = () => {
    return(
    <>
        <footer id="footer" className="footer">
        <div className="copyright">
            &copy; Copyright <strong><span>Simplyfruit</span></strong>. All Rights Reserved 
        </div>
        <div className="credits">
        <span class="badge bg-warning text-dark mx-2"> This is a development environment. </span>
            
        </div>
        <div className="credits">
            Designed by <a href="/">RKM Software</a>
            
        </div>
    </footer>
    <a href="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </>
)
}
export default Footer;
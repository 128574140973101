import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../components/Pagination";
import SelectOptions from "../../components/SelectOptions";
import { Col, Container, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { PrimaryButton } from '../../components/Button';
import { PrintActiveStock } from "./activestockprint";
function Activestockpopup({ ...props }) {
  const componentRef = useRef();
    const [show, setShow] = useState();
    const [totalCounts, setTotalCounts] = useState(0);
    const [sessionsPerPage, setSessionsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(false);
    const [lastUpdateIcon, setLastUpdateIcon] = useState('sort-down');
    const handleClose = () => {
        props.hideFn();
        setSelectedCategory(null)
    };
    useEffect(() => {
        setShow(props.show);
        setTotalCounts(props.data.counts)
        setSessionsPerPage(props.data.limit)
    }, [props]);

    const updateList = (number,lastupdate=false) => {
        setCurrentPage(number)
        props.updateFn(number,selectedCategory, lastupdate)
    }
    const updateCategory = (category) => {
       category ? setSelectedCategory(category) : setSelectedCategory({})
       props.updateFn(1, category)
    }
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const lastUpdateHandle = () =>{
      let show = !lastUpdate

      show ? setLastUpdateIcon('sort-up') : setLastUpdateIcon('sort-down')
      setLastUpdate(show);
      updateList(1,show)
    }
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={'card-col'}>
          <Container fluid>
          <Row> 
            <Col xs={12} md={4}>
            <Modal.Title>Active Stock</Modal.Title>
            </Col>
           <Col xs={12} md={4} className="text-center">
           <SelectOptions
                          options={props.categories}
                          default={selectedCategory}
                          changeFn={updateCategory}
                          isClearable={true}
                          isMulti={true}
                          placeholder={"Category"}
                        />
            </Col>

          <Col xs={12} md={4} className="text-center">

         <PrimaryButton clickfun={handlePrint} buttonClass="primary view-btn float-end no-print" buttonText="Print" iclass="file-earmark-pdf-fill" />
         <PrimaryButton clickfun={lastUpdateHandle} buttonClass="primary view-btn float-end" buttonText="Last Updated" iclass={lastUpdateIcon} />
          <div className={'hide'}>
              <PrintActiveStock ref={componentRef} inventoryData={ props.activePrintData} />
          </div>
            </Col>
          </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
        <div className="table-responsive">
        <table
              className="table"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              style={{ border: '1px solid #dddddd',borderRight:'1px solid #dddddd' }}
            >
              <thead>
                <tr className="table-title">
                  <th>No.</th>
                  <th>Code</th>
                  <th>Cat.</th>
                  <th>Description</th>
                  <th>Loc 1 </th>
                  <th>Imp. QTY.</th>
                  <th>Total QTY</th>
                  <th>Weekly Runrate</th>
                  <th>Run out Days</th>
                  <th>Run out Date</th>
                  <th>Date</th>
                  <th>Next delivery date</th>
                  <th>Days overlap</th>
                  <th>Next delivery QTY</th>
                  <th>Run out days after delivery</th>
                  <th>Next run out date</th>
                </tr>
              </thead>
              <tbody>
              { !props.isLoading ? 
                props.inventoryData.length > 0 ? props.inventoryData.map((product,index) => (
                <tr key={index}>
                  <td>{product.id}</td>
                  <td>{product.product_code}</td>
                  <td>{product.categoroy_title}</td>
                  <td>{product.description}</td>
                  <td>{product.inventory ? product.inventory.quantity : 0}</td>
                  <td>{product.inventory ? product.inventory.imp_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.total_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.weeklyrunrate : 0}</td>
                  <td>{product.inventory ? product.inventory.runsdayout : 0}</td>
                  <td>{product.inventory ? product.inventory.runoutdate : ''}</td>
                  <td>{product.inventory ? product.inventory.order_date : ''}</td>
                  <td>{product.inventory ? product.inventory.next_delivery_date : ''}</td>
                  <td>{product.inventory ? product.inventory.days_over_lap : 0}</td>
                  <td>{product.inventory ? product.inventory.next_delivery_qty : 0}</td>
                  <td>{product.inventory ? product.inventory.run_out_days_after_delivery : 0}</td>
                  <td>{product.inventory ? product.inventory.runoutdate : ''}</td>
                </tr>
                )):  <tr key={1}>
                <td colSpan={16}><div className='alert alert-warning text-center'>No Records Found</div>
                </td>
                </tr> 
                :  <Skeleton style={{
                  width: '100%',
                  marginBottom:5,
                  marginTop:5,
                  height:20
              }} count={10} inline={true} />}
              </tbody>
            </table>
          </div>
          <div className="datatable-bottom">
                                {totalCounts > 10  && !props.isLoading ?
                                <Pagination
                                    itemsCount={totalCounts}
                                    itemsPerPage={sessionsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    alwaysShown={true}
                                    updateList={updateList}
                                />
                            : ''}
                            </div>
        </Modal.Body>
      </Modal> </>
    );
}

export default Activestockpopup;
import React, { useEffect,useState } from "react"
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
import { setPageTitle } from "../../store/resourceSlice";
import DatePicker from "react-datepicker";
const Daliyproductionrecord = () => {
    const dispatch = useDispatch();
    const [planProductsData, setPlanProductsData] = useState([]);
    const [productTypesData, setProductTypesData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const onDateRangeChange = (date) => {
        setStartDate(date);
        if(date){
        let newDate = date.toLocaleDateString("en-UK");
          getRecords(newDate)
        }
    };
    const getRecords = (date) =>{
        getData('/productionrecords?date='+date).then((response) => {
            if (response.status === 200) {
                let responseData = response.data
                if (responseData.success) {
                    setPlanProductsData(responseData.data.products)
                    setProductTypesData(responseData.data.productTypes)
                }
            }
        })
    }
    useEffect(()=>{
        let newDate = startDate.toLocaleDateString("en-UK");
        getRecords(newDate)
        dispatch(setPageTitle('Daliy Production Record'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Daily Production Records',
                "link":'/daliyproductionrecord',
            }
    ]))
    // eslint-disable-next-line
    },[dispatch])
    return(
        <DefaultLayout>
            <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <div className="row mb-5">
                      
                         <div className="col-3">
                         <label className="col-12 col-form-label">Date Filter: </label>
                           <DatePicker
                                        className="form-control "
                                        selected={startDate}
                                        onChange={onDateRangeChange}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Click to select a date"
                                        monthsShown={2}
                                        isClearable
                                        />
                        </div>
                        </div>
                    <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                <td></td>
                                <td>Description</td>
                                               <td>Weight</td>
                                               <td>Size</td>
                                               <td>Cases</td>
                                               <td>Quantity</td>
                                               <td >Type</td>
                                </tr>
                            </thead>
                            <tbody>
                                     { planProductsData.length > 0 ?
                                        planProductsData.map((product, index) => (
                                            <React.Fragment  key={index}>
                                              <tr className={`${index % 2 !== 0 ? " text-black  bg-warning" : "text-white  bg-primary"}`}>
                                               <td className={`${index % 2 !== 0 ? " text-black  bg-warning" : "text-white  bg-success"}`} colSpan={7}>{product.client}</td>
                                               
                                               </tr>
                                                 { product.items.map((product, pindex) => (
                                                    <tr key={index}>
                                                    <td className="tdwitdh-wp150">{product.type}</td>
                                                    <td>{product.product_description}</td>
                                                    <td>{product.weight}</td>
                                                    <td>{parseInt(product.size)}</td>
                                                    <td>{parseInt(product.cases)}</td>
                                                    <td>{parseInt(product.cases)*parseInt(product.size)}</td>
                                                    <td>{product.package_type}</td>
                                                    </tr>
                                                )) }
                                              </React.Fragment>
                                        )):<tr>
                                            <td colSpan={7}>
                                                    <div className="alert alert-warning text-center"> No production records for today</div>
                                            </td>
                                        </tr>
                                    }
                                    {productTypesData.map((type, index)=>(
                                            <tr key={index}>
                                                    <td className="tdwitdh-wp150"></td>
                                                    <td colSpan={2}></td>
                                                    <td>{type.type}</td>
                                                    <td>{Math.trunc(type.cases)}</td>
                                                    <td>{Math.trunc(type.quantity)}</td>
                                                    <td></td>
                                            </tr>
                                    ))}
                                
                            </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                        </section>
        </DefaultLayout>
    )

}
export default Daliyproductionrecord;
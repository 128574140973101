import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData,deleteData } from "../../../services/HttpServices";
import { AlertSuccess,AlertError } from "../../../components/Alerts";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';

const ClientList = () => {

    const dispatch = useDispatch();
    const searchRef = useRef("");

    const [ClientData,setClientData] = useState([])

    const [supplieCounts,setSupplieCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState("")


    let tablColumns = {
        id:'ID',
        name:'Name',
        email:'Email',
        phone:'Phone',
        address_1:'Address',
        status:'Status'
    }
    const getClientData = (page, query='') =>{
        getData('/clients/list?page='+page+'&query='+query).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setClientData(responseData.data.clients)
                setSupplieCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getClientData(pageno,search)
    }
    const filterResult = () =>{
        getClientData(1,search)
    }
    
    const deleteClient = (id)=>{
        if (window.confirm("Are you sure wan't to delete?"))
        {
           deleteData('/clients/delete/'+id).then((response)=>{
                        if(response.status === 200){
                            let resposnseData = response.data
                            if(resposnseData.success === false){
                                AlertError(resposnseData.message)
                                return;
                            }
                            AlertSuccess(resposnseData.message)
                            setPageNo(1)
                            getClientData(1)
                        }
           })
        }
    }
    const updateSearch = (data) => {
        setSearchData(data)
    }
    useEffect(()=>{
        getClientData(pageNo)
        dispatch(setPageTitle('Clients'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Client Address',
                "link":'/clients/addresslist',
            },
            {
                "name":'Clients',
                "link":'/clients',
            }
    ]))
    // eslint-disable-next-line
    },[dispatch])
    const clearFilter = () => {
        setSearchData("")
        getClientData(1,"")
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':ClientData, 'column': tablColumns, counts:supplieCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/clients/' , editLinkTitle : 'Edit Client' }} updateFn = {paginationUpdate} delFun={deleteClient} submitFilter ={filterResult}
                        clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by Name, Email, Phone",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            }
                        ]}
                         submenu={[
                                        {
                                            label:"Add Client",
                                            link:'/clients/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]} />
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default ClientList;
import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AlertSuccess, AlertError } from "../../components/Alerts";
import { postData } from "../../services/HttpServices";
function EditItemLine({ ...props }) {
  const [show, setShow] = useState();
  const handleClose = () => {
    props.hideFn();
  };
  useEffect(() => {
    setShow(props.show);
  }, [props]);
  const updateLine = () => {
    if(window.confirm('Are you sure?')){
        const data = {
            line : props.lines,
            id : props.id
        }
        postData('/productionplan/update',data).then((response) => {
            if (response.status === 200) {
                let responseData = response.data
                if (responseData.success) {
                    AlertSuccess(responseData.message);
                    handleClose()
                    return
                }
                AlertError(responseData.message)
            }
        })
        return
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={"card-col"}>
          <Modal.Title>Edit Production Item </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="section">
            <div className="row">
              <div className="card">
                <div className="card-body mt-5">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Size</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr key={"i_2" }>
                            <td>{props.lines.product_description} {props.id}</td>
                            <td><input className="form-control" type="number"  onChange={(e) => props.lines.size = parseInt(e.target.value) } defaultValue={props.lines.size} /></td>
                            <td><input className="form-control" type="number" onChange={(e) => props.lines.quantity = parseInt(e.target.value) } defaultValue={props.lines.quantity} /></td>
                            </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center">
                    <button type="button" onClick={updateLine} className="btn btn-primary">Submit</button>
                  </div>
                </div>
              </div>
          </section>
        </Modal.Body>
      </Modal>{" "}
    </>
  );
}

export default EditItemLine;

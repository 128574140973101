import React,{useState, useEffect} from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { putData, postMultiPartData } from "../../services/HttpServices"
import { AlertError,AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
// import { useNavigate } from "react-router-dom"
import Skeleton from "react-loading-skeleton";
import { setPageTitle,fetchCurrentLogin  } from '../../store/resourceSlice';
import { useDispatch,useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
const Profile = () => {
    const dispatch = useDispatch();
   // const navigate = useNavigate()

    const currentData = useSelector((state) => state.currentData);

     /*Inital state data*/
     const [userData, setUserData] = useState(null);
     const [isLoding, setIsLoading] = useState(false);
     const [selectedFile, setSelectedFile] = useState();
     const [noImage, setNoImage] = useState("/images/no-image.jpg");

      /*Update Profile Data*/
    const { register, watch, handleSubmit, setValue,formState: { errors } } = useForm();
    const onSubmit = data => {
        data.status = userData.status
        data.role = userData.roleid
        putData('/users/update/'+userData.id,data).then((response) => {
            let resposnseData = response.data
            if(resposnseData.success === false){
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            if(selectedFile){
                uploadFile()
            }
            dispatch(fetchCurrentLogin())

        }).catch(e=>{
            AlertError(e);
        })
    }

    const uploadFile = () => {
        const formData = new FormData();
		formData.append('picture', selectedFile);
		formData.append('id', userData.id);
        postMultiPartData('/profileimage',formData).then((response) => {
           console.log('file uploaded==='+response)

        }).catch(e=>{
            AlertError(e);
        })
    }
     
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
        setNoImage(URL.createObjectURL(event.target.files[0]))
	};
    useEffect(()=>{
        /*Get user data*/
        setValue('name',currentData.profile.name)
        setValue('email',currentData.profile.email)
        setValue('phone',currentData.profile.phone)
        setValue('address',currentData.profile.address)
        setUserData(currentData.profile)
        setNoImage(process.env.REACT_APP_IMAGE_URL+currentData.profile.picture)
        dispatch(setPageTitle('My Profile'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'My Profile',
                "link":'/profile',
            }
    ]))
        if(currentData.profile){
            setIsLoading(true)
        }
    },[dispatch,currentData])
    
    return(
        <DefaultLayout>
            <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        {isLoding && userData ?
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" method="post" >
                                <div className="row mb-3">
                                    <label htmlFor="name" className="col-sm-2 col-form-label">Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="name" defaultValue={userData.name}  {...register("name", {required: true , maxLength: 20})}/>
                                        {errors?.name && errors.name.type === "required" && <span className="error">Please enter name</span>}
                                        {errors?.name && errors.name.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="email" className="col-sm-2 col-form-label">Email<span className="text-danger">*</span></label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control" id="email" defaultValue={userData.email} {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />

                                        {errors?.email && errors.email.type === "required" && <span className="error">Please enter vaild email</span>}
                                        {errors?.email && errors.email.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="phone" className="col-sm-2 col-form-label">Phone<span className="text-danger">*</span></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="phone" defaultValue={userData.phone} {...register("phone", {required: true , maxLength: 20})} />
                                      
                                        {errors?.phone && errors.phone.type === "required" && <span className="error">Please enter phone number</span>}
                                        {errors?.phone && errors.phone.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="address" className="col-sm-2 col-form-label">Address<span className="text-danger">*</span></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="address" defaultValue={userData.address } {...register("address", {required: true , maxLength: 30})} />
                                        
                                        {errors?.address && errors.address.type === "required" && <span className="error">Please enter Address</span>}
                                        {errors?.address && errors.address.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="address" className="col-sm-2 col-form-label">Picture</label>
                                    <div className="col-sm-10">
                                        <input type="file" className="form-control" id="picture" {...register("picture", {required: false})} onChange={changeHandler} />
                                        {errors?.picture && <span className="error">Please select picture</span>}
                                        <div className="mt-2">
                                        {noImage && <img src={noImage} alt="profile"  width={100} height={100} />}
                                        </div>
                                    </div>
                                   
                                </div>
                                <hr />  

                                <div className="row mb-3">
                                    <label htmlFor="password" className="col-sm-2 col-form-label">New Password</label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control" id="password"  {...register("password",{required:false, maxLength:20})}/>
                                        
                                        {errors?.newPasword && errors.newPasword.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>              
                                <div className="row mb-3">
                                    <label htmlFor="confirmPassword" className="col-sm-2 col-form-label">Confirm Password</label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control" id="confirmPassword" {...register("confirmPassword",{
                                            required: false,
                                            maxLength:20,
                                            validate: (val) => {
                                                
                                                if(watch('password') && !val){
                                                    return "Please enter confirm password";
                                                }
                                                
                                                if (watch('password') !== val) {
                                                  return "Your passwords do no match";
                                                }

                                               
                                              },
                                        })} />
                                        {errors?.confirmPassword && errors.confirmPassword.type === "required" && <span className="error">Please enter confirm password</span>}
                                        {errors?.confirmPassword && errors.confirmPassword.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
{/* 
// Use https://bennettfeely.com/clippy/ to try out other shapes */}
{/* <Skeleton style={{
                                     clipPath:
                                     'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)',
                                     display: 'inline-block',
                                     width: '100px',
                                     height: '100px'
                                }} /> */}
                        </div>
                        </div>
                        </div>
                        </section>
        </DefaultLayout>
    )
}
export default Profile;
import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData,postData } from "../../services/HttpServices"
import { useForm } from 'react-hook-form';
import { AlertSuccess,AlertError } from "../../components/Alerts"
import Skeleton from "react-loading-skeleton";
import { setPageTitle  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
const Permissions = () => {
    const dispatch = useDispatch();
    const [modules, setModules] = useState([]);
    const [roles, setRoles] = useState([]);

    const { register, handleSubmit} = useForm();
    const onSubmit = data => {
        postData('/permissions/update',data).then((response) => {
            let resposnseData = response.data
            if(resposnseData.success === false){
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            window.location.reload();
        }).catch(e=>{
            AlertError(e);
        })
    }
    useEffect(()=>{

        const getPermissions = () => {
            getData('/permissions').then((response)=>{
                if(response.status === 200){
                    let responseData = response.data
                    if(responseData.success){
                        setRoles(responseData.data.roles)
                        setModules(responseData.data.modules)
                    }
                }
            })
        }
        getPermissions()
        dispatch(setPageTitle('App Permissions'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'App Permissions',
                "link":'/permissions',
            }
    ]))
    },[dispatch])
    return(
        <DefaultLayout>
            <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="table-responsive">
                        <table className="table">
        <thead>
            <tr>
                <th>Roles</th>
                {roles.length ? roles.map((role, index) => (
                    <th  key={index}>{role.role}</th>
                )):''}
				
            </tr>
        </thead>
        <tbody>
            {/* modules loop start */}
            {modules.length ? modules.map((module, mIndex) => (
				<React.Fragment  key={mIndex}>
                <tr>
					 <td>{module.module_name}</td>
					{/* user loop start */}
                    {module.roles.length > 0 ? module.roles.map((moduleRole, rIndex) => (
						<td  key={rIndex}><input type="checkbox" id={module.module_id} defaultChecked={moduleRole.permission} {...register(`${module.module_id}_${moduleRole.id}`, {required: false})} /></td>
                    )):<td></td>}
					{/* user loop end */}
				</tr>
                </React.Fragment>
                 )):<tr key={1}><td colSpan={modules.length}>
                        <Skeleton style={{
                    width: '100%',
                    marginBottom:5,
                    marginTop:5,
                    height:20
                  }} count={10} inline={true} />
                    </td></tr>}
                {/* modules loop end */}
			    <tr>
                <td>
                  <button type="submit" className="btn btn-primary">Submit</button> 
                  </td> 
                </tr>
    
		</tbody>
    </table>
    </div>
                        </form>
                        </div>
                        </div>
                        </div>
                        </section>
        </DefaultLayout>
    )

}
export default Permissions;
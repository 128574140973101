import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm, Controller } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import Select from "react-select";
const EditProduct = () => {

    const dispatch = useDispatch();
    /*Fetch product id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [productData, setProductData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [itemTypeData, setItemTypeData] = useState([])
    const [locationData, setLocationData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedQa, setSelectedQa] = useState(0)

    /*Update product Data*/
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const onSubmit = data => {
        // if(selectedLocation.length === 0){
        //     AlertError('Please select location');
        //     return
        // }
        // if(selectedCategory.length === 0){
        //     AlertError('Please select category');
        //     return
        // }
       // data.location = selectedLocation
       // data.category = selectedCategory.value
        data.is_qa = selectedQa
        putData('/purchaseproducts/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/purchaseproducts");

        }).catch(e => {
            AlertError(e);
        })
    }
    const categorChangeFn = (res) => {
        res ?  setSelectedCategory(res) : setSelectedCategory([]);
      };

    useEffect(() => {
        /*Get Item Types*/
        const getItemTypes = () => {
            getData('/products/subrecipes').then((response)=>{
                if(response.status === 200){
                    let responseData = response.data
                    if(responseData.success){
                        setItemTypeData(responseData.data)
                    }
                    getCountryOrigins()
            }
            })
        }

        /*Get Catgorires*/
        const getCategories = () => {
            getData('/puchasecategory/filter').then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setCategoryData(responseData.data)
                         getItemTypes()
                    }
                }
            })
        }
        getCategories()

         
        /*Get Country Origin*/
        const getCountryOrigins = () => {
            getData('/puchaselocation/filter').then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setLocationData(responseData.data)
                    }
                    getProductData()
                }
            })
        }

        /*Get product data*/
        const getProductData = () => {
            getData('/purchaseproducts/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setProductData(responseData.data)
                        setSelectedCategory(responseData.data.category_list)
                        setSelectedLocation(responseData.data.location_list)
                        setSelectedQa(responseData.data.is_qa)
                        setIsLoading(true)
                    } else {
                        AlertError(responseData.message)
                        navigate('/purchaseproducts')
                    }
                }
            })
        }
        dispatch(setPageTitle('Edit Product'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Products',
                "link":'/purchaseproducts',
            },
            {
                "name":'Edit Product',
                "link":'/purchaseproducts/'+id,
            }
    ]))
    }, [id, navigate, dispatch])
   
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">


<div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">


                        {isLoading ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={productData && productData.product_code} {...register("code", {required: true , maxLength: 20})} />
                                        {errors?.code && errors?.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors?.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Description<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="description" defaultValue={productData.description}  {...register("description", {required: true , maxLength: 125})} />
                                        {errors?.description && errors?.description.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description && errors?.description.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="category" className="col-sm-12 col-form-label">Category</label>
                                    <div className="col-sm-12">
                                        
                                    {<Controller
                                        control={control}
                                        name="category"
                                        defaultValue={selectedCategory}
                                        rules={{ required: true }}
                                        render={({  field: { onChange } }) => (
                                            
                                            <Select
                                            options={categoryData}
                                            value={selectedCategory}
                                            onChange={(val) => {
                                                categorChangeFn(val)
                                                onChange(val)
                                            }}
                                            isSearchable
                                            isClearable
                                            placeholder={"Select Category"}
                                            />
                                        )}
                                        />}
                            {errors?.category && (
                            <span className="error">
                              Please select a category
                            </span>
                          )}
                                       
                                    </div>
                                  
                                </div>
                                {selectedCategory && (selectedCategory.value === 1 || selectedCategory.value === 8) && <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="itemtype" className="col-sm-12 col-form-label">Recipe Item<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <select id="itemtype" defaultValue={productData.item_type_id} className="form-control" {...register("itemtype", {required: selectedCategory ? true :false })}>
                                            {itemTypeData.length && itemTypeData.map((itemType, index) => (
                                            <option key={index} value={itemType.value}>{itemType.label}</option>
                                        ))}
                                         {errors?.itemtype && <span className="error">Please select a recipe item</span>}
                                        </select>
                                       
                                    </div>
                                  
                                </div> }
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="nominalcode" className="col-sm-12 col-form-label">Nominal Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="nominalcode" defaultValue={ productData.nominal_code }  {...register("nominalcode", {required: true , maxLength: 10})}/>
                                        {errors?.nominalcode && errors?.nominalcode.type === "required" && <span className="error">Please enter nominal code</span>}
                                        {errors?.nominalcode && errors?.nominalcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>   
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="barcode" className="col-sm-12 col-form-label">Barcode</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="barcode" defaultValue={ productData.barcode } {...register("barcode", {required: false, maxLength: 30})}/>
                                        {errors?.barcode && errors?.barcode.type === "required" && <span className="error">Please enter barcode</span>}
                                        {errors?.barcode && errors?.barcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div> 
                                <div className="col-lg-6 col-xl-6 mb-3">
                          <label
                            htmlFor="barcode"
                            className="col-sm-12 col-form-label"
                          >
                            Lead Time
                          </label>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control"
                              id="leadtime"
                              defaultValue={ productData.leadtime }
                              {...register("leadtime", {
                                required: false
                              })}
                            />
                            {errors?.leadtime &&
                              errors.leadtime.type === "required" && (
                                <span className="error">
                                  Please enter leadtime
                                </span>
                              )}
                            {errors?.leadtime &&
                              errors.leadtime.type === "maxLength" && (
                                <span className="error">
                                  Max length exceeded
                                </span>
                              )}
                          </div>
                        </div>     
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="location" className="col-sm-12 col-form-label">Location<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                    {selectedLocation && <Controller
                                        control={control}
                                        name="location"
                                        defaultValue={selectedLocation}
                                        rules={{ required: true }}
                                        render={({  field: { onChange } }) => (
                                            <Select
                                            options={locationData}
                                            value={selectedLocation}
                                            onChange={(val) => {
                                                setSelectedLocation(val)
                                                onChange(val)
                                            }}
                                            isSearchable
                                            isClearable
                                            isMulti
                                            placeholder={"Select Location"}
                                            />
                                         )}
                                        />}
                                {errors?.location && (
                                    <span className="error">
                                    Please select a location
                                    </span>
                                )}
                                    </div>
                                  
                                </div> 
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="is_qa" className="col-sm-12 col-form-label">Is QA Required</label>
                                    <div className="col-sm-12">
                                        <input
                                            type="checkbox"
                                            id="is_qa"
                                            name="is_qa"
                                            checked={selectedQa ? 1 : 0}
                                            defaultValue={selectedQa? selectedQa : 0}
                                            {...register("is_qa",{
                                                onChange:(res)=>{
                                                    if (res.target.checked){
                                                        setSelectedQa(1)
                                                    }else{
                                                        setSelectedQa(0)
                                                    }
                                                }
                                            })}
                                            />
                                        
                                    </div>
                                   
                                </div>  
                                  
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                               </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditProduct;
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { AlertError,AlertSuccess } from "../../../components/Alerts"
import { postData,getData } from '../../../services/HttpServices';
import SelectOptions from '../../../components/SelectOptions';
import AddClientPopUp from './AddClientPopUp';
function Addaddresspopup({...props}) {
    const [show, setShow] = useState();
    const [clientshow, setClientShow] = useState();
    const [clientid, setClientId] = useState();
    const [taxCodeData, setTaxCodeData] = useState([])
    const [currency, setCurrency] = useState([])
    const handleClose = () => {
        props.hideFn()
    };
      useEffect(()=>{
        setShow(props.show)
        /*Get clients list*/
      },[props])
      const { register,handleSubmit, formState: { errors }, reset  } = useForm();

      const onSubmit = data => {
        if(!clientid){
            AlertError('Please select a client first')
        }
        data.is_list = props.isAddressList ? props.isAddressList : false;
        postData('/clients/address/add/'+clientid,data).then((response) => {
            let resposnseData = response.data
            if(resposnseData.success === false){
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            reset({
                code: "",
                location: "",
                contactname: "",
                contactnumber: "",
                address1: "",
                address_2: "",
                county: "",
                country: "",
                postcode: "",
              })
            props.setAddressFn()
            props.hideFn()
        }).catch(e=>{
            AlertError(e);
        })
    }
    const changeOptionsFn = (data) => {
        (data) ? setClientId(data.value) : setClientId(0)
    }
    const showAddClientPopUp = () =>{
        /*Get clients list*/
            getData("/taxcodes/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setTaxCodeData(responseData.data);
                    }
                }
            });
            getData("/currency").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setCurrency(responseData.data);
                    }
                }
            });
        setClientShow(true)
    }
    const hideClientAddPopUp = () => {
        props.refreshClient()
        setClientShow(false)
    }
    const refreshClient = () => {
        props.refreshClient()
    }
    useEffect(()=>{
        if(props.selectedClient){
            setClientId(props.selectedClient.value)
        }
    },[props])
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
         <Modal.Header closeButton>
          <Modal.Title>
            Add New Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {props.clientData && 
                            <div className="row mb-3">
                                    <label htmlFor="client" className="col-4 col-form-label">Client<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <SelectOptions id="client" options={props.clientData} default={props.selectedClient} changeFn={changeOptionsFn} placeholder={'Select Client'} isClearable={true} />
                                        {props.showAddClient && 
                                        <button onClick={showAddClientPopUp} className="btn btn-primary btn-sm mt-2" title="Add New Client"><i className="bi bi-database-fill-add" /></button>
                                      }
                                    </div>
                                </div>
                                }
                                <div className="row mb-3">
                                    <label htmlFor="code" className="col-4 col-form-label">GLN Code<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="code" defaultValue=""  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="code" className="col-4 col-form-label">Location<span className="text-danger">*</span>
                                    <small className="text-secondary mx-2"><i className="bi bi-info-circle-fill" title="Code Ref: DONCASTER: 5000119000954,DIDCOT: 5000119001157,RAINHAM: 5000119001351,SNODLAND: 5000119001759,HINCKLEY: 5000119001953,SOUTHAMPTON: 5000119002350,PETERBOROUGH: 5000119003157,LIVINGSTON: 5000119005052,BELFAST: 5000119008350,AVONMOUNTH: 5000119008459,WIDNES: 5000119008756,	DUBLIN:5000119007353, "></i></small>
                                    </label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="location" defaultValue=""  {...register("location", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.location.type === "required" && <span className="error">Please enter location</span>}
                                        {errors?.code && errors.location.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="contactname" className="col-4 col-form-label">Contact Name<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="contactname" defaultValue="" {...register("contactname", {required: true , maxLength: 20})} />
                                        {errors?.contactname && errors.contactname.type === "required" && <span className="error">Please enter contact name</span>}
                                        {errors?.contactname && errors.contactname.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="contactnumber" className="col-4 col-form-label">Contact Number<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="contactnumber" defaultValue="" {...register("contactnumber", {required: true , maxLength: 20})} />
                                        {errors?.contactnumber && errors.contactnumber.type === "required" && <span className="error">Please enter contact number</span>}
                                        {errors?.contactnumber && errors.contactnumber.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="address1" className="col-4 col-form-label">Address 1<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="address1" defaultValue="" {...register("address1", {required: true , maxLength: 128})} />
                                        {errors?.address1 && errors.address1.type === "required" && <span className="error">Please enter address 1</span>}
                                        {errors?.address1 && errors.address1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="address_2" className="col-4 col-form-label">Address 2</label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="address_2" defaultValue="" {...register("address_2", {required: false , maxLength: 128})} />
                                        {errors?.address_2 && errors.address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="county" className="col-4 col-form-label">County<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="county" defaultValue="" {...register("county", {required: true , maxLength: 20})} />
                                        {errors?.county && errors.county.type === "required" && <span className="error">Please enter county</span>}
                                        {errors?.county && errors.county.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="country" className="col-4 col-form-label">Country<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="country" defaultValue="" {...register("country", {required: true , maxLength: 20})} />
                                        {errors?.country && errors.country.type === "required" && <span className="error">Please enter country</span>}
                                        {errors?.country && errors.country.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="postcode" className="col-4 col-form-label">Post Code</label>
                                    <div className="col-6">
                                        <input type="text" className="form-control" id="postcode" defaultValue="" {...register("postcode", {required: false , maxLength: 10})} />
                                        {errors?.postcode && errors.postcode.type === "required" && <span className="error">Please enter post code</span>}
                                        {errors?.postcode && errors.postcode.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <input type='hidden' {...register("status")} defaultValue={1} />
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                        </div>
                        </div>
           </section>
           {props.showAddClient && 
                <AddClientPopUp show={clientshow} hideFn={hideClientAddPopUp} showAddClient={true}  setAddressFn={refreshClient} taxCodeData={taxCodeData} currency={currency} />
            }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Addaddresspopup;
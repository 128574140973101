import React, { useEffect, useState } from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { useNavigate, useParams } from "react-router-dom"
import { getData, putData } from "../../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../../components/Alerts"
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton"
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const EditCategory = () => {
    const dispatch = useDispatch();
    /*Fetch user id */
    const navigate = useNavigate()
    const { id } = useParams()


    /*Inital state data*/
    const [categoryData, setCategoryData] = useState(null);


    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        putData('/puchasecategory/update/' + id, data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/category");
        }).catch(e => {
            AlertError(e);
        })
    }


    useEffect(() => {
        /*Get supplier data*/
        const getCategoryData = () => {
            getData('/puchasecategory/details/' + id).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        setCategoryData(responseData.data)
                    } else {
                        AlertError(responseData.message)
                        navigate('/category')
                    }
                }
            })
        }
        getCategoryData()
        dispatch(setPageTitle('Edit Category'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Categories',
                "link": '/category',
            },
            {
                "name": 'Edit Category',
                "link": '/category/' + id,
            }
        ]))
    }, [id, navigate, dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
            <div className="card-body">
                            <div className="container py-5 h-100">
<div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-6 col-xl-6">
                        {categoryData ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                               <div className="col-lg-12 col-xl-12 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Title<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="title" defaultValue={categoryData.title}  {...register("title", {required: true , maxLength: 20})}/>
                                        {errors?.title && errors.title.type === "required" && <span className="error">Please enter title</span>}
                                        {errors?.title && errors.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-12 col-xl-12 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Description<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <textarea className="form-control" id="description" rows={10} cols={5} defaultValue={categoryData.description}  {...register("description", {required: true , maxLength: 125})} ></textarea>
                                        {errors?.description && errors.description.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description && errors.description.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                 </div>
                            </form>
:<Skeleton style={{
    width: '100%',
    marginBottom:5,
    marginTop:5,
    height:20
  }} count={10} inline={true} />}
                        </div>
                        </div>
                        </div>
                          </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default EditCategory;
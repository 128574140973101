import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
export const Printfruitmaster = React.forwardRef((props, ref) => {
  return (
    <DefaultLayout>
      <section className="section invoice-section">
        <div className="row">
          <div className="card-body mt-3">
            <div className="card">
              <div className="card-body" ref={ref}>
              <table
              className="table"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              style={{ border: '1px solid #dddddd',borderRight:'1px solid #dddddd' }}
            >
              <thead>
                <tr className="table-title">
                  <th>Supplier</th>
                  <th>Product</th>
                  <th>Weight(KG)</th>
                  <th>QTY.</th>
                  <th>Pro. weight(KG)</th>
                  <th>Case Price</th>
                  <th>Price/KG</th>
                  <th>Hauler</th>
                  <th>Location</th>
                  <th>Collection Location</th>
                  <th>Collection Date</th>
                  <th>Delivery Date</th>
                  <th>GRN</th>
                  <th>Arrival</th>
                </tr>
              </thead>
              <tbody>
                { props.orderData && props.orderData.map((order,index) => (
                <React.Fragment key={index}>
                   { order.items.map((item,itemIndex) => (
                    <tr key={index+'_'+itemIndex}>
                        <td>{item.supplier_name}</td>
                        <td>{item.goods_description}</td>
                        <td>{item.unit_quantity}{item.unit}</td>
                        <td>{item.quantity}</td>
                        <td>{item.unit_quantity * item.quantity} </td>
                        <td><span dangerouslySetInnerHTML={{ __html: order.currency_symbol }}/>{item.price}</td>
                        <td><span dangerouslySetInnerHTML={{ __html: order.currency_symbol }}/>{(item.price / item.unit_quantity).toFixed(2) }</td>
                        <td>{order.haulier}</td>
                        <td>{item.location}</td>
                        <td>{order.loading_place}</td>
                        <td>{order.collection_date}</td>
                        <td>{order.delivery_date}</td>
                        <td>{item.grn}</td>
                        <td>{item.arrival}</td>
                    </tr>
                   ) )}
                </React.Fragment>
                ))}
              </tbody>
            </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
});



import React, { useEffect, useState,useRef } from "react";
import Table from "../../components/Table";
import { getData } from "../../services/HttpServices";
const DeBulkList = ({ ...props }) => {
    const searchRef = useRef("");
    const [debulkData,setDebulkData] = useState([])
    const [debulkCounts,setDebulkCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [productData,setProductData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [search,setSearchData] = useState("")
    const [selectedId,setSelectedId] = useState(null)

    let tablColumns = {
        product_code:'Product Code',
        description:'Product Description',
        quantity:'Quantity',
        user_name:'Requested By',
        de_status:'Status',
    }

    const getDebulkData = (pageNo, query='',product='') =>{
        setIsLoading(true)
        if(product){
            product = product.value
        }
        let date = props.currentDate.toLocaleDateString("en-UK");
        getData('/debulk/list?page='+pageNo+'&query='+query+'&product='+product+'&date='+date).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setDebulkData(responseData.data.list)
                setDebulkCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
            setIsLoading(false)
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getDebulkData(pageno)
    }
    const filterResult = () =>{
        getDebulkData(1,search,selectedId)
    }
    
    const updateSearch = (data) => {
        setSearchData(data)
    }
    useEffect(()=>{
        getDebulkData(pageNo)
        getData('/debulk/products/filter').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data)
            }
        })

        // eslint-disable-next-line
    },[pageNo])

    const changeProduct = (data)=>{
        setSelectedId(data)
    }
    const clearFilter = () => {
        searchRef.current.value = ''
        getDebulkData(1)
        setSelectedId(0)
        setSearchData("")
    }
    return(
            <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':debulkData,'column': tablColumns, counts:debulkCounts, pageno: pageNo,limit:limit, isNext:isNext }} updateFn = {paginationUpdate} submitFilter={filterResult} clearfilter={clearFilter} 
                        filters={[         
                            {
                                label:"Find by Code, Description",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            },{
                                label:"Select Product",
                                type:"select",
                                class:'form-control',
                                options:productData,
                                default:selectedId,
                                isClearable:true,
                                changeOptionsFn:changeProduct
                            }
                        ]}
                        isLoading={isLoading}/>    
                        </div>
                        </div> 
                        </div>
                        </section>
    )

}
export default DeBulkList
import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Col, Container, Row } from "react-bootstrap";
import { PrimaryButton } from "../../components/Button";
function Salegridpopup({ ...props }) {
  const [show, setShow] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const onDateRangeChange = (date) => {
    setStartDate(date);
    if (date) {
      let newDate = date.toLocaleDateString("en-UK");
      props.filterData(newDate);
    }
  };
  const handleClose = () => {
    props.hideFn();
  };
  useEffect(() => {
    setShow(props.show);
  }, [props]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={"card-col"}>
          <Container fluid>
            <Row>
              <Col xs={12} md={4}>
                <Modal.Title>Sale Grid</Modal.Title>
              </Col>
              <Col xs={12} md={4} className="text-center">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={onDateRangeChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Click to select a date"
                  monthsShown={1}
                  isClearable
                />
              </Col>
              <Col xs={12} md={4} className="text-center">
                {props.totalpallets > 0 && (
                  <PrimaryButton
                    clickfun={() =>
                      props.zippdf(startDate.toLocaleDateString("en-UK"))
                    }
                    buttonText={"Download Pdf"}
                    title={"Download Pdf"}
                    buttonClass={"primary view-btn"}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          {props.locations.length > 0 && (
            <React.Fragment>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 text-center card-col">
                    <div className="d-flex justify-content-between">
                      <span>
                        <b>Dispatch Date :</b> {props.date}
                      </span>
                      <span>
                        <b>Delivery Date :</b> {props.date}
                      </span>
                      <span>
                        <b>Use by date :</b> {props.useByDate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table
                  className="table"
                  width="100%"
                  cellPadding="0"
                  cellSpacing="0"
                  style={{
                    border: "1px solid #dddddd",
                    borderRight: "1px solid #dddddd",
                  }}
                >
                  <thead>
                    <tr className="table-title">
                      <th></th>
                      <th></th>
                      {props.locations.map((location, index) => (
                        <th
                          colSpan={2}
                          align="center"
                          key={index}
                          style={{
                            border: "1px solid #dddddd",
                            backgroundColor:
                              index % 2 !== 0 ? "#ffff99" : "#ccff99",
                          }}
                        >
                          {location}
                        </th>
                      ))}
                      <th></th>
                      <th
                        colSpan="2"
                        align="center"
                        style={{ border: "1px solid #dddddd" }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.salegrids.length > 0 ? (
                      <React.Fragment>
                        {props.salegrids.map((product, index) => (
                          <tr key={"s_" + index}>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                borderRight: "1px solid #dddddd",
                              }}
                            >
                              {product.type} - {product.package_type}{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                borderRight: "1px solid #dddddd",
                              }}
                            >
                              {" "}
                              {product.product_description}
                            </td>
                            {props.locations.map((location, indexl) =>
                              product.location === location ? (
                                <React.Fragment key={"l_" + indexl}>
                                  <td
                                    style={{
                                      backgroundColor:
                                        indexl % 2 !== 0
                                          ? "#ffff99"
                                          : "#ccff99",
                                    }}
                                  >
                                    {product.pallet_no}{" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      backgroundColor:
                                        indexl % 2 !== 0
                                          ? "#ffff99"
                                          : "#ccff99",
                                    }}
                                  >
                                    {product.cases}
                                  </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={"l_" + indexl}>
                                  <td
                                    style={{
                                      backgroundColor:
                                        indexl % 2 !== 0
                                          ? "#ffff99"
                                          : "#ccff99",
                                    }}
                                  >
                                    0
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      backgroundColor:
                                        indexl % 2 !== 0
                                          ? "#ffff99"
                                          : "#ccff99",
                                    }}
                                  >
                                    0
                                  </td>
                                </React.Fragment>
                              )
                            )}
                            <td colSpan="2" align="right">
                              <b>{product.total}</b>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <tr key={"e1"}>
                          <td colSpan={props.locations.length + 22}>
                            <div className="alert alert-warning text-center">
                              {" "}
                              No records found
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
                <table className="table" cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr key={"e2"}>
                      <th
                        colSpan={4}
                        style={{
                          border: "0px solid #dddddd",
                          borderRight: "0px solid #dddddd",
                          borderTop: "0px solid #dddddd",
                        }}
                      ></th>
                    </tr>
                    <tr key={"e3"}>
                      <th
                        style={{
                          border: "0px solid #dddddd",
                          borderRight: "0px solid #dddddd",
                          borderTop: "0px solid #dddddd",
                        }}
                        colSpan={4}
                      ></th>
                      <th
                        className="text-right w-12"
                        style={{
                          borderRight: "1px solid #dddddd",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Total Pallets
                      </th>
                      <th
                        className="text-right w-12"
                        style={{
                          borderRight: "1px solid #dddddd",
                          border: "1px solid #dddddd",
                        }}
                      >
                        {props.totalpallets}
                      </th>
                    </tr>
                    <tr key={"e4"}>
                      <th
                        colSpan={4}
                        style={{
                          border: "0px solid #dddddd",
                          borderRight: "0px solid #dddddd",
                          borderTop: "0px solid #dddddd",
                        }}
                      ></th>
                    </tr>
                    {props.producttypes.length > 0 &&
                      props.producttypes.map((types, typeIndex) => (
                        <tr
                          key={"pt_" + typeIndex}
                          style={{
                            border: "0px solid #dddddd",
                            borderRight: "0px solid #dddddd",
                            borderTop: "0px solid #dddddd",
                          }}
                        >
                          <th
                            style={{
                              border: "0px solid #dddddd",
                              borderRight: "0px solid #dddddd",
                              borderTop: "0px solid #dddddd",
                            }}
                          ></th>
                          <th
                            style={{
                              border: "0px solid #dddddd",
                              borderRight: "0px solid #dddddd",
                              borderTop: "0px solid #dddddd",
                            }}
                          ></th>
                          <th
                            style={{
                              border: "0px solid #dddddd",
                              borderRight: "0px solid #dddddd",
                              borderTop: "0px solid #dddddd",
                            }}
                          ></th>
                          <th
                            className="text-right w-12"
                            style={{ backgroundColor: "#e4e4e4" }}
                          >
                            {types.type}
                          </th>
                          <th
                            className="text-right w-12"
                            style={{ backgroundColor: "#e4e4e4" }}
                          >
                            {types.pallet}
                          </th>
                          <th
                            className="text-right w-12"
                            style={{ backgroundColor: "#e4e4e4" }}
                          >
                            {types.cases}
                          </th>
                        </tr>
                      ))}
                  </thead>
                </table>
                <table className="table mt-2">
                  <tr className="table-title">
                    {props.locations.map((location, index) => (
                      <th
                        colSpan={2}
                        align="center"
                        key={index}
                        style={{
                          border: "1px solid #dddddd",
                          backgroundColor:
                            index % 2 !== 0 ? "#ffff99" : "#ccff99",
                          textAlign: "center",
                        }}
                      >
                        {location}
                      </th>
                    ))}
                  </tr>
                  <tbody>
                    {props.locationpallets.map((locationpallet, loc) => (
                      <tr style={{ textAlign: "center" }}>
                        {props.locations.map((location, index) =>
                          locationpallet.location === location ? (
                            <React.Fragment>
                              <td>{locationpallet.pallet_no}</td>
                              <td>{locationpallet.total}</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <td>0</td>
                              <td>0</td>
                            </React.Fragment>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
          {props.locations.length === 0 && (
            <div className="alert alert-warning text-center">
              {" "}
              No records found
            </div>
          )}
        </Modal.Body>
      </Modal>{" "}
    </>
  );
}

export default Salegridpopup;

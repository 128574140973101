import React, { useEffect } from "react"
import DefaultLayout from "../../layouts/DefaultLayout"
import { postData } from "../../services/HttpServices"
import { AlertError, AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { setPageTitle } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../store/breadcrumbSlice';
const AddTaxCode = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate()

    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/taxcodes/add', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/taxcodes");
        }).catch(e => {
            AlertError(e);
        })
    }
    useEffect(() => {
        dispatch(setPageTitle('Edit Supplier Tax Codes'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Supplier Tax Codes',
                "link": '/taxcodes',
            },
            {
                "name": 'Add Supplier Tax Codes',
                "link": '/taxcodes/add',
            }
        ]))
    }, [dispatch])
    return (
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body">
                                       <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">

<div className="col-lg-12 col-xl-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="name" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue=''  {...register("code", {required: true , maxLength: 3})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="col-sm-12 col-form-label">Vat Rate<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="vatrate" defaultValue='' {...register("vatrate", {required: true , maxLength: 4})} />
                                        {errors?.vatrate && errors.vatrate.type === "required" && <span className="error">Please enter vat rate</span>}
                                        {errors?.vatrate && errors.vatrate.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                  </div>
                            </form>
                        </div>
                        </div>
                        </div>
                                                </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default AddTaxCode;
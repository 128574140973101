import React from "react";

const AuthLayout = ({ children }) =>{
    return(
        <>
        <main id="login-page">
                <div className="container-fluid">
                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container-fluid">
                            <div className="row">
                            {children}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}
export default AuthLayout;
import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { postData } from "../../services/HttpServices";
import { AlertError,AlertSuccess } from "../../components/Alerts";
import Select from "react-select";
function AddLinePopUp({ ...props }) {
    const [show, setShow] = useState();
    const [lines, setLines] = useState([])
    const handleClose = () => {
        props.hideFn()
    };
    useEffect(() => {
        setShow(props.show)
    }, [props])

    const updateLineHandler = (res) => {
        setLines(res)
    }

    const updateLine = ()=>{
        if(window.confirm('Are you sure?')){
            const data = {
                line : lines,
                id : props.id
            }
            postData('/productionplan/line',data).then((response) => {
                if (response.status === 200) {
                    let responseData = response.data
                    if (responseData.success) {
                        AlertSuccess(responseData.message);
                        handleClose()
                        return
                    }
                    AlertError(responseData.message)
                }
            })
            return
        }
    }
    const LinesChunck = [
        {
            'label': 1,
            'value':1
        },
        {
            'label': 2,
            'value':2
        },
        {
            'label': 3,
            'value':3
        },
        {
            'label': 4,
            'value':4
        },
        {
            'label': 5,
            'value':5
        },
        {
            'label': 6,
            'value':6
        }
    ]
    
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-md"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={'card-col'}>
        <Modal.Title>Add Production Lines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                            <div className="row mb-3">
                                    <label htmlFor="lines" className="col-2 col-form-label">Lines</label>
                                    <div className="col-10">
                                                        <Select
                                                         defaultValue={props.current}
                                                         options={LinesChunck}
                                                         placeholder="Select Lines"
                                                         isMulti={true}
                                                         onChange={(res) => updateLineHandler(res)}
                                                     />
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="button" onClick={updateLine} className="btn btn-primary">Submit</button>
                                </div>
                        </div>
                        </div>
                        </div>
           </section>
        </Modal.Body>
      </Modal> </>
    );
}

export default AddLinePopUp;
import React, { useState, useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom"
import { getData, postData } from "../../../services/HttpServices";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { setPageTitle } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const CreatePurchaseOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()


    const [supplierId, setSupplierId] = useState(0);
    const [itemtype, setItemtype] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState("");
    const [collectiondate, setCollectiondate] = useState("");
    const [CommodityCode, setCommodityCode] = useState("");
    const [haulier, setHaulier] = useState("");
    const [collectionLocation, setCollectionLocation] = useState("");

    const [location, setLocationData] = useState("");
    const [supplierData, setSupplierData] = useState([]);
    const [itemTypeData, setItemTypeData] = useState([]);
    const [deliveryCharges, setDeliveryCharges] = useState(0);
    const [commissionCharges, setCommissionCharges] = useState(0);
    const [productRows, setProductRows] = useState([{ product_id: 0, quantity: 0, unit: "", sub_unit: "", unit_quantity: 0, total_quantiy: 0,price: 0,symbol:'',created_at:'' }]);
    const [productData, setProductData] = useState([]);
    const [enabled, setEnabled] = useState(true);


    /*Add Purchase order Data*/
    const unitTypes = [{ value: 'case', label: 'Case' }, { value: 'reel', label: 'Reel' }, { value: 'roll', label: 'Roll' }, { value: 'box', label: 'Box' }, { value: 'bin', label: 'Bin' }, { value: 'pallet', label: 'Pallet' }, { value: 'pack', label: 'Pack' }, { value: 'kg', label: 'KG' }]

    const subUnits = [
      { value: 'case', label:"Case"},
      { value: 'pixels', label:"Pixels"},
      { value: 'roll', label:"Roll"},
      { value: 'box', label:"Box"},
      { value: 'bin', label:"Bin"},
      { value: 'size', label:"Size"},
      { value: 'kg', label:"KG"},
      { value: 'grms', label:"GRMS"},
    ]

    const onSubmit = () => {
      
     
        if (!supplierId) {
            AlertError("Please choose a supplier");
            return;
        }
        if (!itemtype) {
            AlertError("Please choose a type");
            return;
        }
       
        if (!collectiondate) {
            AlertError("Please select a collection date");
            return;
        }
        if (!collectionLocation) {
            AlertError("Please select a collection location");
            return;
        }
        if (!deliveryDate) {
            AlertError("Please select a delivery date");
            return;
        }
        if (productRows.length === 0) {
            AlertError("Please add a product.");
            return;
        }
        let errors = productRows.filter((res) => {
            return res.product_id === 0 || res.unit === "" || res.sub_unit === "" || res.quantity === 0 || res.price === 0 || res.unit_quantity === 0;
        });
        if (errors.length > 0) {
            AlertError("The product section required attention. All fields are required.");
            return;
        }
        let newDeliveryDate = deliveryDate.toLocaleDateString("en-UK");
        let newColloectionDate = collectiondate.toLocaleDateString("en-UK");
        const postArr = {
            deliverydate: newDeliveryDate,
            collectiondate: newColloectionDate,
            supplier: supplierId,
            itemtype: itemtype,
            location: location,
            collectionlocation: collectionLocation,
            haulier: haulier,
            commoditycode: CommodityCode,
            deliverycharge: deliveryCharges,
            commissioncharges: commissionCharges,
            productRows: productRows
        }
        
        setEnabled(false)
        postData('/purchase/order', postArr).then((response) => {
          setEnabled(true)
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/purchaseorders");
        }).catch(e => {
          setEnabled(true)
            AlertError(e);
        })
    };

    const addProduct = () => {
        if (supplierId) {
            getProductData(supplierId)
        }

        let newAdded = [...productRows, { product_id: 0, quantity: 0, unit: "", sub_unit: "", unit_quantity: 0, total_quantiy: 0, lastprice:0, price: 0,symbol:'',created_at:'' }];
        setProductRows(newAdded);

    };
    const removeProduct = (index) => {
        let newRows = productRows.filter((arrow, key) => key !== index);
        setProductRows(newRows);
    };

    const updateProductRow = (res, index) => {
        let temp = [...productRows];
        productRows[index]["product_id"] = (res) ? res.value : 0
        productRows[index]["lastprice"] = (res) ? res.last_price : 0
        productRows[index]["symbol"] = (res) ? res.symbol : ''
        productRows[index]["created_at"] = (res) ? res.created_at : ''
        setProductRows(temp)
    }

    const changeItemTypeHandler = (res) => {
        res ? setItemtype(res.value) : setItemtype(0);
    }
    const updateTotalQuanity = (value, index, type) => {
        let temp = [...productRows];

        if (type === 'unit')
            temp[index].total_quantiy = temp[index].quantity * value;
        if (type === 'quantity')
            temp[index].total_quantiy = temp[index].unit_quantity * value;

        setProductData(temp)
    }
    const supplierChangeHandler = (res) => {
        if (res) {
            setSupplierId(res.value)
            getProductData(res.value)
        } else {
            setSupplierId(0);
            setProductData([])
        }
    }


    /*Get Products Data*/
    const getProductData = (supplierId) => {
        getData("/purchaseproducts/filter/" + supplierId).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                if (responseData.success === false) {
                  AlertError(responseData.message);
                  return
                }
                setProductData(responseData.data);
            }
        });
    };


    useEffect(() => {
        const getSuppliers = () => {
            getData("/suppliers/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setSupplierData(responseData.data);
                    }
                }
            });
        };
        getSuppliers();

        const getItemTypeData = () => {
            getData("/itemtypes/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    setItemTypeData(responseData.data);
                }
            });
        };
        getItemTypeData();

        dispatch(setPageTitle('Create Purchase Order'))
        dispatch(setBreadcrumbs([{
                "name": 'Dashboard',
                "link": '/dashboard',
            },
            {
                "name": 'Purchase Orders',
                "link": '/purchaseorders',
            },
            {
                "name": 'Create Purchase Order',
                "link": '/purchaseorders/add',
            }
        ]))
    }, [dispatch]);
    return (
        <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
                     <div className="card-body">
                    <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-12">
                    <div className="row">
              <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="supplier" className="col-12 col-form-label">Supplier<span className="text-danger">*</span></label>
                            <div className="col-12">
                                    <Select
                                        options={supplierData}
                                        defaultValue={supplierId}
                                        onChange={supplierChangeHandler}
                                        isSearchable={true}
                                        id="supplier"
                                        isClearable={true}
                                        placeholder='Select a supplier'
                                      />
                                    </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="itemtype" className="col-12 col-form-label">Item Type<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <Select
                                    options={itemTypeData}
                                    defaultValue={itemtype}
                                    onChange={changeItemTypeHandler}
                                    id="itemtype"
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder='Select item type'
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="collectiondate" className="col-12 col-form-label">Collection Date<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <DatePicker
                                    className="form-control col-6"
                                    selected={collectiondate}
                                    id="deliverydate"
                                    onChange={(date) => setCollectiondate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Click to select a date"
                                    monthsShown={2}
                                    minDate={new Date()}
                                    isClearable={collectiondate}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="collectionLocation" className="col-12 col-form-label">Collection Location<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    selected={collectionLocation}
                                    id="collectionLocation"
                                    onInput={(res) => setCollectionLocation(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="deliverydate" className="col-12 col-form-label">Delivery Date<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <DatePicker
                                    className="form-control col-6"
                                    selected={deliveryDate}
                                    id="deliverydate"
                                    onChange={(date) => setDeliveryDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Click to select a date"
                                    monthsShown={2}
                                    minDate={new Date()}
                                    isClearable={deliveryDate}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="haulier" className="col-12 col-form-label">Delivery method / haulier</label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    selected={haulier}
                                    id="haulier"
                                    onInput={(res) => setHaulier(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="CommodityCode" className="col-12 col-form-label">Commodity Code</label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    selected={CommodityCode}
                                    id="CommodityCode"
                                    onInput={(res) => setCommodityCode(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="location" className="col-12 col-form-label">Country of Origin</label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    selected={location}
                                    id="location"
                                    onInput={(res) => setLocationData(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="deliveryCharges" className="col-12 col-form-label">Delivery charge (if applicable):</label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    type="number"
                                    step={1.00}
                                    defaultValue={deliveryCharges}
                                    pattern="[0-9]"
                                    id="deliveryCharges"
                                    onInput={(res) => setDeliveryCharges(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="ommissionCharges" className="col-12 col-form-label">Commissions / Surcharges (if applicable)</label>
                            <div className="col-12">
                            <input
                                    className="form-control"
                                    type="number"
                                    step={1.00}
                                    defaultValue={commissionCharges}
                                    pattern="[0-9]"
                                    id="ommissionCharges"
                                    onInput={(res) => setCommissionCharges(res.target.value)}
                                  />
                              </div>
                                   
                         </div>
              <hr />
              <div className="col-12 mb-3">
                <h5 className="card-title">Products<span className="text-danger">*</span></h5>
                {productRows.length > 0 && <small className="text-danger">Note: The products associated with the supplier will be an option to choose from.</small> }
              </div>
              {productRows.length > 0 &&
                productRows.map((product, index) => (
                  <div className="row g-3 mb-3" key={index}>
                    <div className="col-6 col-md-2">
                      <label className="col-form-label">Product<span className="text-danger">*</span></label>
                      <Select
                        options={productData}
                        defaultValue={product.product_id}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=>updateProductRow(res, index)}
                      />
                    </div>
                    <div className="col-6 col-md-1">
                      <label className="col-form-label">Quantity<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        min={1}
                        defaultValue={product.quantity}
                        onInput={(res) => {
                          productRows[index]["quantity"] = res.target.value;
                          updateTotalQuanity(res.target.value,index,'quantity')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <label className="col-form-label">Unit<span className="text-danger">*</span></label>
                      <Select
                        options={unitTypes}
                        defaultValue={product.unit}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=> {
                          productRows[index]["unit"] = (res)  ? res.value : ""
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <label className="col-form-label">Sub Unit<span className="text-danger">*</span></label>
                      <Select
                        options={subUnits}
                        defaultValue={product.sub_unit}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=> {
                          productRows[index]["sub_unit"] = (res)  ? res.value : ""
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <label className="col-form-label">Qty. per unit<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={product.unit_quantity}
                        min={1}
                        onInput={(res) => {
                          productRows[index]["unit_quantity"] = res.target.value;
                          updateTotalQuanity(res.target.value,index,'unit')
                        }}
                      />
                      <small className="text-primary">Total Quantity : { productRows[index].total_quantiy }</small>
                    </div>
                    <div className="col-6 col-md-2">
                      <label className="col-form-label">Price<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        step={1.00}
                        defaultValue={product.price}
                        pattern="[0-9]"
                        onChange={(res) => {
                          productRows[index]["price"] = res.target.value;
                        }}
                      />
                      {product.lastprice > 0 &&
                       <small className="text-info">Last Price:<span dangerouslySetInnerHTML={{ __html: product.symbol }}/>{product.lastprice} @ {product.created_at}</small> 
                      }
                    </div>
                    { productRows.length > 1 && 
                    <div className="col-6 col-md-1">
                      <div className="mt-2-5">
                        <DeleteButton
                          iclass="trash-fill"
                          buttonClass="danger"
                          clickfun={() => {if(window.confirm('Delete the item?')){ removeProduct(index) };}} 
                        />
                      </div>
                    </div>
                    }
                  </div>
                ))}
              <div className="row g-3 mb-3">
                <div className="col-6 col-md-3">
                  <PrimaryButton
                    title="Add Product"
                    clickfun={addProduct}
                    iclass="plus"
                    buttonClass='primary'
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  onClick={onSubmit}
                  className="btn btn-primary sub-btn"
                  disabled={!enabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
                 </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
    );
};
export default CreatePurchaseOrder;
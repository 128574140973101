import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm } from "react-hook-form";
import { setPageTitle } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { setBreadcrumbs } from "../../../store/breadcrumbSlice";
const AddSaleProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(0);
  const [selectedSubRecipe, setSelectedSubRecipe] = useState([]);
  const [categoryRows, setCategoryRows] = useState([
    { category_id: 0, products: [], product_id: 0, quantity: 0 },
  ]);
  /*Add Product Data*/
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (categoryRows.length === 0) {
      AlertError("Please add a product.");
      return;
    }
    let errors = categoryRows.filter((res) => {
      return (
        res.product_id === 0 || res.quantity === 0 || res.category_id === 0
      );
    });
    if (errors.length > 0) {
      AlertError(
        "The Packaging Information section required attention. All fields are required."
      );
      return;
    }
    data.recipe = selectedRecipe;
    data.packaging = categoryRows;
    postData("/products/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/products");
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  /*Recipe Combinations*/
  const getRecipeCombination = (recipe_id) => {
    getData("/recipecombination/" + recipe_id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        if (responseData.success) {
          setSelectedSubRecipe(responseData.data);
        } else {
          AlertError(responseData.message);
          navigate("/products");
        }
      }
    });
  };

  const addCategory = () => {
    let newAdded = [
      ...categoryRows,
      { category_id: 0, products: [], product_id: 0, quantity: 0 },
    ];
    setCategoryRows(newAdded);
  };
  const removeCategroy = (index) => {
    let newRows = categoryRows.filter((arrow, key) => key !== index);
    setCategoryRows(newRows);
  };

  const updateCategoryRow = (res, index) => {
    let category = res ? res : {};
    categoryRows[index]["category_id"] = category;
    if (Object.keys(category).length)
      getProductByCategory(category.value, index);
  };
  const updateProductRow = (res, index) => {
    categoryRows[index]["product_id"] = res ? res : [];
  };
  const getProductByCategory = (category_id, index) => {
    getData("/categoryproducts/filter/" + category_id).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        let rows = [...categoryRows];
        rows[index]["products"] = responseData.data;
        setCategoryRows(rows);
      }
    });
  };

  useEffect(() => {
    const getRecipes = () => {
      getData("/products/recipes").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setRecipes(responseData.data);
            // getSubRecipes()
          }
        }
      });
    };
    getRecipes();
    const filtercategory = () => {
      getData("/products/filtercategory").then((response) => {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setCategoryData(responseData.data);
          }
        }
      });
    };
    filtercategory();
    dispatch(setPageTitle("Add New Product"));
    dispatch(
      setBreadcrumbs([
        {
          name: "Dashboard",
          link: "/dashboard",
        },
        {
          name: "Products",
          link: "/products",
        },
        {
          name: "Add New Product",
          link: "/products/add",
        },
      ])
    );
  }, [dispatch]);
  return (
    <DefaultLayout>
      <section className="section">
  <div className="row">
    <div className="card">
      <div className="container py-2 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-9 col-xl-9">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="name"
                      className="col-sm-2 col-form-label"
                    >
                      Code<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        defaultValue=""
                        {...register("code", { required: true, maxLength: 20 })}
                      />
                      {errors?.code && errors.code.type === "required" && (
                        <span className="error">Please enter code</span>
                      )}
                      {errors?.code && errors.code.type === "maxLength" && (
                        <span className="error">Max length exceeded</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="name"
                      className="col-sm-2 col-form-label"
                    >
                      Description<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="description"
                      defaultValue=""
                      {...register("description", {
                        required: true,
                        maxLength: 125,
                      })}
                    />
                    {errors?.description &&
                      errors.description.type === "required" && (
                        <span className="error">Please enter description</span>
                      )}
                    {errors?.description &&
                      errors.description.type === "maxLength" && (
                        <span className="error">Max length exceeded</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="name"
                      className="col-sm-4 col-form-label"
                    >
                      Supplier Description<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="supplierdescription"
                      defaultValue=""
                      {...register("supplierdescription", {
                        required: true,
                        maxLength: 125,
                      })}
                    />
                    {errors?.supplierdescription &&
                      errors.supplierdescription.type === "required" && (
                        <span className="error">Please enter description</span>
                      )}
                    {errors?.supplierdescription &&
                      errors.supplierdescription.type === "maxLength" && (
                        <span className="error">Max length exceeded</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="barcode"
                      className="col-sm-2 col-form-label"
                    >
                      Barcode
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="barcode"
                      defaultValue=""
                      {...register("barcode", {
                        required: false,
                        maxLength: 30,
                      })}
                    />
                    {errors?.barcode && errors.barcode.type === "required" && (
                      <span className="error">Please enter barcode</span>
                    )}
                    {errors?.barcode && errors.barcode.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="weight"
                      className="col-sm-2 col-form-label"
                    >
                      Weight<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="weight"
                      defaultValue=""
                      {...register("weight", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    {errors?.weight && errors.weight.type === "required" && (
                      <span className="error">
                        Please enter valild weight. Should be number
                      </span>
                    )}
                    {errors?.weight && errors.weight.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    {errors?.weight &&
                      errors.weight.type === "valueAsNumber" && (
                        <span className="error">
                          Please enter weight in number
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="size"
                      className="col-sm-2 col-form-label"
                    >
                      Size<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="size"
                      defaultValue=""
                      {...register("size", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    {errors?.size && errors.size.type === "required" && (
                      <span className="error">
                        Please enter valild Size. Should be number
                      </span>
                    )}
                    {errors?.size && errors.size.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    {errors?.size && errors.size.type === "valueAsNumber" && (
                      <span className="error">Please enter size in number</span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="recipe"
                      className="col-sm-2 col-form-label"
                    >
                      Recipe<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <Select
                      options={recipes}
                      defaultValue={selectedRecipe}
                      onChange={(res) => {
                        if (res) {
                          setSelectedRecipe(res);
                          getRecipeCombination(res.value);
                        } else {
                          setSelectedRecipe(0);
                          setSelectedRecipe([]);
                        }
                      }}
                      isSearchable={true}
                      id="recipes"
                      isClearable={true}
                      placeholder="Select Recipe"
                    />
                    {selectedSubRecipe.length > 0 && (
                      <small className="mt-2">
                        {selectedSubRecipe.map((SubRecipe, srIndex) => (
                          <span
                            key={srIndex}
                            className="badge bg-secondary mx-1 text-white"
                          >
                            <b>{SubRecipe.label} : </b>
                            <b>{SubRecipe.recipe_value}%</b>
                          </span>
                        ))}
                      </small>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <label
                      htmlFor="status"
                      className="col-sm-2 col-form-label"
                    >
                      Status<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <select
                      id="status"
                      className="form-control"
                      defaultValue={1}
                      {...register("status", { required: true })}
                    >
                      <option value={1}>Active</option>
                      <option value={2}>In-Active</option>
                    </select>
                    {errors?.status && (
                      <span className="error">Please select a status</span>
                    )}
                    </div>
                    <div className="col-lg-12 col-xl-12 mb-3">
                          <label
                            htmlFor="is_qa"
                            className="col-sm-3 col-form-label"
                          >
                            Is QA Required
                          </label>
                          <div className="col-sm-10">
                          <select
                      id="is_qa"
                      className="form-control"
                      defaultValue={1}
                      {...register("is_qa", { required: true })}
                    >

                        <option value={2}>No</option>
                      <option value={1}>Yes</option>
                    </select>
                          </div>
                        </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-3">
                    <h5 className="card-title">Packaging Information<span className="text-danger">*</span></h5>
                  </div>
                  {categoryRows.length > 0 &&
                    categoryRows.map((category, index) => (
                      <div className="row g-3 mb-3" key={index}>
                        <div className="col-3">
                          <label className="col-form-label">
                            Category<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={categoryData}
                            defaultValue={category.category_id}
                            className="col-12"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(res) => updateCategoryRow(res, index)}
                          />
                        </div>
                        <div className="col-3">
                          <label className="col-form-label">
                            Products<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={categoryRows[index].products}
                            defaultValue={category.product_id}
                            className="col-12"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(res) => updateProductRow(res, index)}
                          />
                        </div>
                        <div className="col-3">
                          <label className="col-form-label">
                            Quantity<span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            min={1}
                            defaultValue={1}
                            onInput={(res) => {
                              categoryRows[index]["quantity"] = res.target.value;
                            }}
                          />
                        </div>
                        {categoryRows.length > 1 && (
                          <div className="col-3">
                            <div className="mt-2-5">
                              <DeleteButton
                                iclass="trash-fill"
                                buttonClass="danger"
                                clickfun={() => {
                                  if (window.confirm("Delete the item?")) {
                                    removeCategroy(index);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="row g-3 mb-3">
                    <div className="col-3">
                      <PrimaryButton
                        title="Add Data"
                        clickfun={addCategory}
                        iclass="plus"
                        buttonClass="primary"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary sub-btn"
                    >
                      Submit
                    </button>
                  </div>{" "}
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </DefaultLayout>
  );
};
export default AddSaleProduct;

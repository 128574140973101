import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DeBulkList from '../debulk/index'
function Debulk({ ...props }) {
    const [show, setShow] = useState();
    const handleClose = () => {
        props.hideFn()
    };
    useEffect(() => {
        setShow(props.show)
    }, [props])

   
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={'card-col'}>
        <Modal.Title>Debulk Requests ({props.currentDate.toLocaleDateString("en-UK")})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <DeBulkList currentDate={props.currentDate} />
        </Modal.Body>
      </Modal> </>
    );
}

export default Debulk;
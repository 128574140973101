import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import Addaddresspopup from "./addresspopup";
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
const ClientAddressList = () => {
    const dispatch = useDispatch();
    const searchRef = useRef("");
    const [addressData,setAddressData] = useState([])
    const [clientData,setClientData] = useState([])
    const [selectedClient,setSelected] = useState(null)

    const [supplieCounts,setSupplieCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [show, setShow] = useState(false);
    const [search,setSearchData] = useState("")
    let tablColumns = {
        id:'ID',
        client_name:'Client Name',
        gln_code:'GLN Code',
        location:'Location',
        contact_name:'Contact Name',
        contact_number:'Contact Number',
        address_1:'Address'
    }
    const getAddressData = (page, query='',client='') =>{
        getData('/clients/addresses?page='+page+'&query='+query+'&client='+client).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setAddressData(responseData.data.addresses)
                setSupplieCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        let client = ''
        if(selectedClient)
            client = selectedClient.value

        getAddressData(pageno,search,client)
    }
    const filterResult = () =>{
        let client = ''
        if(selectedClient)
            client = selectedClient.value
        getAddressData(1,search,client)
    }

    const showAddressPopUp = () =>{
        setShow(true)
    }
    const hideAddressPopUp = () =>{
        setShow(false)
    }
    const updateSearch = (data) => {
        setSearchData(data)
    }
    const updateClient = (res) => {
        setSelected(res)
    }
    const refreshClient = () =>{
        getData('/clients/filter').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setClientData(responseData.data)
            }
        })
    }
    useEffect(()=>{
        getAddressData(pageNo)
        dispatch(setPageTitle('Clients Address'))
      
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Clients Address',
                "link":'/clients/addresslist',
            }
    ]))
    refreshClient()
      // eslint-disable-next-line 
    },[dispatch])
    const clearFilter = () => {
        setSearchData("")
        getAddressData(1,"")
        searchRef.current.value = ''
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':addressData, 'column': tablColumns, counts:supplieCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/clients/address/' }} updateFn = {paginationUpdate} submitFilter ={filterResult}  clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Search by GLN Code, Location",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:updateSearch
                            },
                            {
                                label:"Select Client",
                                type:"select",
                                class:'form-control',
                                name:"client",
                                options:clientData,
                                default:selectedClient,
                                changeOptionsFn:updateClient
                            }
                        ]}
                        submenu={[
                            {
                                label:"Clients",
                                link:'/clients',
                                buttonClass:'primary float-end btn-sm mx-2 view-btn'

                            },
                            {
                                label:"Add Address",
                                fun:showAddressPopUp,
                                buttonClass:'primary float-end btn-sm mx-2 view-btn'

                            }
                        ]} />
                        </div>
                        </div>
                        </div>
           </section>
           <Addaddresspopup show={show} setAddressFn={()=> getAddressData(1)} hideFn={hideAddressPopUp} isAddressList={true} clientData={clientData} refreshClient={refreshClient} showAddClient={true}/>
        </DefaultLayout>
    )
}
export default ClientAddressList;
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";
import { Link } from 'react-router-dom';
import { getData } from "../../services/HttpServices";
import { useReactToPrint } from 'react-to-print';
import { PrimaryButton } from '../../components/Button';
import { Printfruitmaster } from './fruitmasterprint';
function Fruitmasterpopup({ ...props }) {
  const componentRef = useRef();
    const [show, setShow] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [orderData, setOrderData] = useState([]);
    const [isitemData, setIsitemData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
   
    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            let newStart = start.toLocaleDateString("en-UK");
            let newend = end.toLocaleDateString("en-UK");
            getOrderData(newStart, newend)
        }
    };
    const handleClose = () => {
        props.hideFn()
    };
    useEffect(() => {
        setShow(props.show)
    }, [props])

    useEffect(() => {
        var d = new Date(); // today!
        var x = 30; // go back 5 days!
        d.setDate(d.getDate() - x);
        setStartDate(d)
        let newStart = d.toLocaleDateString("en-UK");
        let newend = endDate.toLocaleDateString("en-UK");
        getOrderData(newStart, newend) // eslint-disable-next-line
    }, [props.location])
    

    const getOrderData = (startDate = "", endDate = "") => {
      setIsLoading(true);
      let location = ''
      if(props.location){
        location = props.location
      }
      getData("/fruitmaster?start=" + startDate + "&end=" + endDate+ "&location=" + location).then(
          (response) => {
              if (response.status === 200) {
                  let responseData = response.data;
                  setOrderData(responseData.data.orders);
                  setIsitemData(responseData.data.isitem);
              }
              setIsLoading(false);
          }
      );
    };

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        backdrop="static"
        keyboard={false}
      >
      <Modal.Header closeButton className={'card-col'}>
      <Container fluid>
          <Row>
            <Col xs={12} md={4}>
            <Modal.Title>Fruit Master</Modal.Title>
            </Col>
         <Col xs={12} md={4} className="text-center">
            <DatePicker
                     className="form-control"
                      selected={startDate}
                      onChange={onDateRangeChange}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Click to select a date"
                      monthsShown={2}
                      selectsRange
                      isClearable
              />
            </Col>
         <Col xs={12} md={4} className="text-center">
         <PrimaryButton clickfun={handlePrint} buttonClass="primary view-btn float-end no-print" buttonText="Print" iclass="file-earmark-pdf-fill" />
                      <div className={'hide'}>
                     <Printfruitmaster ref={componentRef} orderData={orderData} />
                    </div>
            </Col>
          </Row>
          </Container>
              
             
         
        </Modal.Header>
        <Modal.Body>
        
        <div className="table-responsive">
         <table
              className="table"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              style={{ border: '1px solid #dddddd',borderRight:'1px solid #dddddd' }}
            >
              <thead>
                <tr className="table-title">
                  <th>No.</th>
                  <th>Supplier</th>
                  <th>Product</th>
                  <th>Weight(KG)</th>
                  <th>QTY.</th>
                  <th>Pro. weight(KG)</th>
                  <th>Case Price</th>
                  <th>Price/KG</th>
                  <th>Hauler</th>
                  <th>Location</th>
                  <th>Collection Location</th>
                  <th>Collection Date</th>
                  <th>Delivery Date</th>
                  <th>GRN</th>
                  <th>Arrival</th>
                </tr>
              </thead>
              <tbody>
                { !isLoading ? 
                isitemData ? orderData && orderData.map((order,index) => (
                <React.Fragment key={index}>
                   { order.items.map((item,itemIndex) => (
                    <tr key={index+'_'+itemIndex}>
                        <td><Link to={'/purchaseorders/details/'+order.id} target='_blank'> {item.id}</Link></td>
                        <td>{item.supplier_name}</td>
                        <td><Link to={'/purchaseorders/details/'+order.id} target='_blank'>{item.goods_description}</Link></td>
                        <td>{item.unit_quantity}{item.unit}</td>
                        <td>{item.quantity}</td>
                        <td>{item.unit_quantity * item.quantity} </td>
                        <td><span dangerouslySetInnerHTML={{ __html: order.currency_symbol }}/>{item.price}</td>
                        <td><span dangerouslySetInnerHTML={{ __html: order.currency_symbol }}/>{(item.price / item.unit_quantity).toFixed(2) }</td>
                        <td>{order.haulier}</td>
                        <td>{item.location}</td>
                        <td>{order.loading_place}</td>
                        <td>{order.collection_date}</td>
                        <td>{order.delivery_date}</td>
                        <td>{item.grn}</td>
                        <td>{item.arrival}</td>
                    </tr>
                   ) )}
                </React.Fragment>
                )) :  <tr key={1}>
                <td colSpan={15}><div className='alert alert-warning text-center'>No Records Found</div>
                </td>
                </tr> 
                :  <Skeleton style={{
                  width: '100%',
                  marginBottom:5,
                  marginTop:5,
                  height:20
              }} count={10} inline={true} />} 
                  
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal> </>
    );
}

export default Fruitmasterpopup;
import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useDispatch,useSelector } from 'react-redux';
import { fetchCurrentLogin } from '../store/resourceSlice';
const DefaultLayout = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCurrentLogin())
    }, [dispatch]);

    const breadcrumbs = useSelector((state) => state.breadcrumbData);
    return (
         <>
        <Header/> 
        <Sidebar />
        <main id="main" className="main">
             { breadcrumbs.breadcrumbs  && breadcrumbs.breadcrumbs.length > 0 &&
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
               { breadcrumbs.breadcrumbs.map((breadcrumb, index) => (
                <li className="breadcrumb-item"  key={index}><Link to={breadcrumb.link}>{breadcrumb.name}</Link></li>
                ))}
                 </ol>
                </nav>
           }
         {children}
        </main> 
        <Footer />
        </>
    )
}

export default DefaultLayout;
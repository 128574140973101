import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux';
import store from './store/store'
/*Routes*/
import Login from './pages/auth/Login';
import Forgot from "./pages/auth/Forgot";
import Dashboard from './pages/dashboard/index';
import UserList from "./pages/user/List";
import EditUser from "./pages/user/EditUser";
import AddUser from './pages/user/AddUser';
import Profile from "./pages/others/Profile";
import SuppliersList from "./pages/suppliers/List";
import EditSupplier from "./pages/suppliers/EditSupplier";
import AddSupplier from './pages/suppliers/AddSupplier';
import Settiings from "./pages/others/Settings";
import Permissions from "./pages/others/Permissions";
import LocationList from "./pages/purchase/location/List";
import AddLocation from "./pages/purchase/location/AddLocation";
import EditLocation from "./pages/purchase/location/EditLocation";

import CategoryList from "./pages/purchase/category/List";
import AddCategory from "./pages/purchase/category/AddCategory";
import EditCategory from "./pages/purchase/category/EditCategory";

import TaxCodeList from "./pages/taxcodes/List";
import AddTaxCode from "./pages/taxcodes/AddTaxCode";
import EditTaxCode from "./pages/taxcodes/EditTaxCode";

import TypeList from "./pages/itemtypes/List";
import AddType from "./pages/itemtypes/AddType";
import EditType from "./pages/itemtypes/EditType";


import ProductList from "./pages/purchase/products/List";
import AddProduct from "./pages/purchase/products/AddProduct";
import EditProduct from "./pages/purchase/products/EditProduct";

import Inventory from "./pages/inventory/index";
import ProductInventoryHistory from "./pages/inventory/prductInventoryHistory";

import CreatePurchaseOrder from "./pages/purchase/orders/create";
import PurchaseOrderList from "./pages/purchase/orders";
import PurchaseOrderView from "./pages/purchase/orders/view";

import SaleProductList from "./pages/sales/products/List";
import AddSaleProduct from "./pages/sales/products/AddProduct";
import EditSaleProduct from "./pages/sales/products/EditProduct";

import ClientList from "./pages/sales/clients/List";
import AddClient from "./pages/sales/clients/AddClient";
import EditClient from "./pages/sales/clients/EditClient";
import ClientAddressList from "./pages/sales/clients/Addresses";
import EditAddress from "./pages/sales/clients/EditAddress";

import SaleOrderList from "./pages/sales/orders";
import SaleOrderView from "./pages/sales/orders/view";
import CreateSaleOrder from "./pages/sales/orders/create";

//import Reports from "./pages/purchase/reports";

import WebPermissions from "./pages/others/WebPermissions";
import Daliyproductionrecord from "./pages/production/Daliyproductionrecord";
import Daliyproductionplan from "./pages/production/Daliyproductionplan";

import RecipeList from "./pages/sales/recipes";
import AddRecipe from "./pages/sales/recipes/AddRecipe";
import EditRecipe from "./pages/sales/recipes/EditRecipe";

import Rawoutput from "./pages/reports/Rawoutput";

function App() {
  return (
    <Provider store={store}>
     <ToastContainer />
      <Router>
        <Routes>
            <Route path="/" exact={true} Component={Login} />
            <Route path="/forgot" exact={true} Component={Forgot} />
            <Route path="/dashboard" Component={Dashboard} />
            <Route path="/profile" Component={Profile} />
            <Route path="/users" Component={UserList} />
            <Route path="/users/:id" Component={EditUser}  />
            <Route path="/users/add" Component={AddUser}  />

            <Route path="/suppliers" Component={SuppliersList} />
            <Route path="/suppliers/:id" Component={EditSupplier}  />
            <Route path="/suppliers/add" Component={AddSupplier}  />

            <Route path="/clients" Component={ClientList} />
            <Route path="/clients/:id" Component={EditClient}  />
            <Route path="/clients/add" Component={AddClient}  />
            <Route path="/clients/addresslist" Component={ClientAddressList}  />
            <Route path="/clients/address/:id" Component={EditAddress}  />

            <Route path="/locations" Component={LocationList}  />
            <Route path="/locations/:id" Component={EditLocation}  />
            <Route path="/locations/add" Component={AddLocation}  />


            <Route path="/category" Component={CategoryList}  />
            <Route path="/category/:id" Component={EditCategory}  />
            <Route path="/category/add" Component={AddCategory}  />

            <Route path="/taxcodes" Component={TaxCodeList}  />
            <Route path="/taxcodes/:id" Component={EditTaxCode}  />
            <Route path="/taxcodes/add" Component={AddTaxCode}  />

            <Route path="/itemtypes" Component={TypeList}  />
            <Route path="/itemtypes/:id" Component={EditType}  />
            <Route path="/itemtypes/add" Component={AddType}  />


            <Route path="/purchaseproducts" Component={ProductList}  />
            <Route path="/purchaseproducts/add" Component={AddProduct}  />
            <Route path="/purchaseproducts/:id" Component={EditProduct}  />

            <Route path="/products" Component={SaleProductList}  />
            <Route path="/products/add" Component={AddSaleProduct}  />
            <Route path="/products/:id" Component={EditSaleProduct}  />

            <Route path="/recipes" Component={RecipeList}  />
            <Route path="/recipes/add" Component={AddRecipe} />
            <Route path="/recipes/:id" Component={EditRecipe}  />


            <Route path="/inventory" Component={Inventory}  />
            <Route path="/singleinventoryhistory/:id" Component={ProductInventoryHistory}  />


            <Route path="/purchaseorders" Component={PurchaseOrderList} />
            <Route path="/purchaseorders/add" Component={CreatePurchaseOrder} />
            <Route path="/purchaseorders/details/:id" Component={PurchaseOrderView} />

            <Route path="/orders" Component={SaleOrderList} />
            <Route path="/orders/add" Component={CreateSaleOrder} />
            <Route path="/orders/details/:id" Component={SaleOrderView} />

            {/* <Route path="/reports" Component={Reports}  /> */}

            <Route path="/settings" Component={Settiings}  />
            <Route path="/permissions" Component={Permissions}  />
            <Route path="/webpermissions" Component={WebPermissions}  />
            <Route path="/daliyproductionrecord" Component={Daliyproductionrecord}  />
            <Route path="/daliyproductionplan" Component={Daliyproductionplan}  />

            <Route path="/rawfruits" Component={Rawoutput} />



        </Routes>
    </Router>
    </Provider>
  );
}

export default App;

import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { putData } from "../../services/HttpServices";
import { AlertError,AlertSuccess } from "../../components/Alerts";
function AddQuanitityPopUp({ ...props }) {
    const [show, setShow] = useState();
    const [quantity, setQuantity] = useState(0)
    const handleClose = () => {
        props.hideFn()
    };
    useEffect(() => {
        setShow(props.show)
    }, [props])
    const updateInventory = () =>{
        if (window.confirm("Are you sure wan't to add quantity to current stock?"))
        {
            const data = {
                quantity 
            }
           putData('/inventory/updateinventory/'+props.id,data).then((response)=>{
                        if(response.status === 200){
                            let resposnseData = response.data
                            if(resposnseData.success === false){
                                AlertError(resposnseData.message)
                                return;
                            }
                            AlertSuccess(resposnseData.message)
                            setQuantity(0)
                            props.hideFn()
                        }
           })
        }
    }
    
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-md"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className={'card-col'}>
        <Modal.Title>Update Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                            <div className="row mb-3">
                                    <label htmlFor="quantity" className="col-4 col-form-label">Quantity<span className="text-danger">*</span></label>
                                    <div className="col-6">
                                    <input type="number" min="0.00" step="1.00" id='quantity'  className="form-control" defaultValue={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                    </div>
                                   
                                </div>
                                <div className="text-center">
                                    <button type="button" onClick={updateInventory}className="btn btn-primary">Submit</button>
                                </div>
                        </div>
                        </div>
                        </div>
           </section>
        </Modal.Body>
      </Modal> </>
    );
}

export default AddQuanitityPopUp;
import React, { useEffect } from "react"
import DefaultLayout from "../../layouts/DefaultLayout"
import { postData } from "../../services/HttpServices"
import { AlertError,AlertSuccess } from "../../components/Alerts"
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { setPageTitle  } from '../../store/resourceSlice';
import { useDispatch } from 'react-redux';
const AddType = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    /*Update User Data*/
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/itemtypes/add',data).then((response) => {
            let resposnseData = response.data
            if(resposnseData.success === false){
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/itemtypes");
        }).catch(e=>{
            AlertError(e);
        })
    }
    useEffect(()=>{
        dispatch(setPageTitle('Add Product Item Types'))
    },[dispatch])
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <label htmlFor="name" className="col-sm-2 col-form-label">Code</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="code" defaultValue=''  {...register("code", {required: true , maxLength: 20})}/>
                                        {errors?.code && errors.code.type === "required" && <span className="error">Please enter code</span>}
                                        {errors?.code && errors.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="phone" className="col-sm-2 col-form-label">Title</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="title" defaultValue='' {...register("title", {required: true , maxLength: 20})} />
                                        {errors?.title && errors.title.type === "required" && <span className="error">Please enter title</span>}
                                        {errors?.title && errors.title.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                    
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default AddType;
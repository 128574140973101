import React,{ useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Col, Container, Row } from 'react-bootstrap';
import  SelectOptions  from '../../components/SelectOptions';
import { PrimaryButton } from "../../components/Button";
function Runratepopup({ ...props }) {
    const [show, setShow] = useState();
    const [startDate, setStartDate] = useState(props.date);
    const [category, setCategory] = useState(null);

    const onDateRangeChange = (date) => {
      (date) ? setStartDate(date) : setStartDate(null);
       props.changeDate(date)
    };
    const changeOptionsFn = (data) => {
      setCategory(data)
    }
    const filterData = () => {
     
      let date = '';
      if(startDate){
          date = startDate.toLocaleDateString("en-UK");
      }
      let selectedcategory = (category) ? category.value : ''
      props.filterData(date,selectedcategory)
    }
    const handleClose = () => {
        props.hideFn();
    };
    useEffect(() => {
        setShow(props.show);
       
    }, [props]);
    
    useEffect(()=>{
      filterData()
      // eslint-disable-next-line
    },[])
    return ( <>
        <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
        backdrop="static"
        keyboard={false}
      >
     <Modal.Header closeButton className={'card-col'}>
          <Container fluid>
            <Row>
              <Col xs={12} md={3}>
                <Modal.Title>Run Rates</Modal.Title>
              </Col>
              <Col xs={12} md={3} className="text-center">
                <DatePicker
                  className="form-control"
                  selected={props.date}
                  onChange={onDateRangeChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Click to select a date"
                  monthsShown={1}
                  isClearable
                />
              </Col>
              <Col xs={12} md={3} className="text-center">
                <SelectOptions
                options={props.categorylist} default={category} changeFn={changeOptionsFn} placeholder={'Select a category'} isClearable={true}
                />
              </Col>
              <Col xs={12} md={3} className="text-center">
               <PrimaryButton clickfun={filterData} buttonClass={'primary view-btn'} buttonText={'Filter'} />
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table width="100%" cellPadding="5px" cellSpacing="0">
              <tbody>
                {props.runrates.length > 0 ? (
                            props.runrates.map((category, rindex) => (
                              <React.Fragment key={'c_'+rindex}>
                                <tr className="table-title" >
                                <td style={{ border: "1px solid #dddddd" ,backgroundColor:"#dddddd",  borderRight:'1px solid #dddddd'}} className="fw-bold">
                                  {category.title}
                                </td>
                                <td colSpan={2} style={{ border: "1px solid #dddddd" ,backgroundColor:"#dddddd",  borderRight:'1px solid #dddddd'}} className="fw-bold">
                                 
                                </td>
                              </tr>
                               {category.products.map((product, index) => (
                              <tr className="table-title" key={'p_'+index}>
                                <td style={{ border: "1px solid #dddddd", borderRight:'1px solid #dddddd'}}>
                                  {product.description}
                                </td>
                                <td style={{ border: "1px solid #dddddd", borderRight:'1px solid #dddddd' }}>
                                  {product.total_quantity}{" "}
                                </td>
                              </tr>
                              ))}
                              </React.Fragment>
                             
                            ))) 
              :<React.Fragment>
                <tr key={'e1'}>
                    <td colSpan={4}><div className="alert alert-warning text-center"> No records found</div></td>
                </tr>
              </React.Fragment>
              }
              </tbody>

            </table>
          </div>
        </Modal.Body>
      </Modal> </>
    );
}

export default Runratepopup;
import React, { useEffect, useState,useRef } from "react";
import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";

import { setPageTitle  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../store/breadcrumbSlice';
import { useSearchParams } from 'react-router-dom';

const PurchaseOrderList = () => {
    const searchRef = useRef("");
    const [searchParams,setSearchParmas] = useSearchParams();
    const dispatch = useDispatch();
    const [orderData,setOrderData] = useState([])

    const [orderCounts,setOrderCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [statusData, setStatusData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [defaultStatus, setDefaultStatus] = useState(null);


    let tablColumns = {
        id:'ID',
        supplier_name:'Supplier Name',
        //qa:'QA',
        sage_po_code:'Order Number',
        collection_date:'Collection Date',
        delivery_date:'Delivery Date',
        item_type:'Item Type',
        total_quantity:'Total Quantity',
        order_status:'Status'
    }
    const getOrderData = (pageNo,search='',date='',status='') =>{
        let url = '/purchase/orders/list?page='+pageNo+'&query='+search;
        if(date){
            url +='&start='+date.start+'&end='+date.end
        }
        if(status){
            url +='&status='+status
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setOrderData(responseData.data.orders)
                setOrderCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
        
    }
    const paginationUpdate = (pageno) => {

        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
        setPageNo(pageno)
        getOrderData(pageno,search,date,selectedStatus)
    }
    const onSearchChange = (data) => {
       setSearchData(data)
    };
    const changeStatus = (data)=>{
         if(data) {
            setSearchParmas({'status':data.value})
            setSelectedStatus(data.value)
            setDefaultStatus(data)
         } else{
            setSelectedStatus(null)
            setDefaultStatus(null)
            setSearchParmas({'status':''})
         }
    }
    const clearFilter = () => {
        getOrderData(1)
        setSelectedStatus(null)
        setDefaultStatus(null)
        setSearchParmas({'status':''})
        searchRef.current.value = ''
    }
    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };
   
     const submitFilter = () => {
        let date = {
            'start': (startDate) ?  startDate.toLocaleDateString("en-UK") : "",
            'end': (endDate) ? endDate.toLocaleDateString("en-UK") : "",
         }
         getOrderData(1,search,date,selectedStatus)
     }
     useEffect(()=>{
        getData('/purchase/status').then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setStatusData(responseData.data)
            }
        })
        dispatch(setPageTitle('Purchase Orders'))
        dispatch(setBreadcrumbs([
            {
            "name":'Dashboard',
            "link":'/dashboard',
            },
            {
                "name":'Purchase Orders',
                "link":'/purchaseorders',
            }
    ]))
    if(searchParams && searchParams.get('status')){
        var queryStringsStatus  =searchParams.get('status')
        if(queryStringsStatus === "1"){
            setSelectedStatus(1)
            setDefaultStatus({label: "Pending", value:1})
        }
        if(queryStringsStatus === "2"){
            setSelectedStatus(3)
            setDefaultStatus({"label":"In Process","value":2})
        }
        if(queryStringsStatus === "3"){
            setSelectedStatus(3)
            setDefaultStatus({"label":"Goods Received","value":3})
        }
        if(queryStringsStatus === "4"){
            setSelectedStatus(4)
            setDefaultStatus({"label":"Packaging","value":4})
        }
        if(queryStringsStatus === "5"){
            setSelectedStatus(5)
            setDefaultStatus({"label":"QA Approved","value":5})
        }
        if(queryStringsStatus === "7"){
            setSelectedStatus(7)
            setDefaultStatus({"label":"SM QA Approval","value":7})
        }
        if(queryStringsStatus === "8"){
            setSelectedStatus(8)
            setDefaultStatus({"label":"Complete","value":8})
        }
    }
    setTimeout(()=>{
        getOrderData(pageNo,search,'',queryStringsStatus)
    },500)
    // eslint-disable-next-line
    },[dispatch])
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                      
                        <Table data={{'list':orderData, 'column': tablColumns, counts:orderCounts, pageno: pageNo,limit:limit, isNext:isNext, 'viewLink': '/purchaseorders/details/' }} updateFn = {paginationUpdate} submitFilter={submitFilter} clearfilter={clearFilter}
                        filters={[         
                            {
                                label:"Find by Supplier Name ",
                                type:'search',
                                class:'form-control',
                                default:search,
                                ref:searchRef,
                                searchFilterFn:onSearchChange
                            },{
                                label:"Date Range",
                                type:'daterange',
                                class:'form-control',
                                startDate:startDate,
                                endDate:endDate,
                                placeholderText:'Select Date Range',
                                dateRangeFn:onDateRangeChange
                            },
                            {
                                label:"Select Status",
                                type:"select",
                                class:'form-control',
                                options:statusData,
                                default:defaultStatus,
                                changeOptionsFn:changeStatus
                            }
                        ]}
                        submenu={[{
                                            label:"Create New",
                                            link:'/purchaseorders/add',
                                            buttonClass:'primary float-end '

                                        }
                                    ]}
                                    /> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default PurchaseOrderList;